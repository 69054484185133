<template>
    <Layout>
        <main :class="$style.page">
            <template v-if="isUserInfoLoaded">
                <section
                    v-if="isAuthorized && user"
                    :class="$style.auth_title_page"
                >
                    <Typography
                        is="h1"
                        type="header"
                        size="m"
                    >
                        {{ t('page_title_auth') }}
                    </Typography>
                    <PromoInviteHint />
                </section>
                <section
                    v-else
                    :class="$style.non_auth_title_page"
                >
                    <Typography
                        is="h1"
                        type="header"
                        size="l"
                    >
                        {{ t('page_title_non_auth') }}
                    </Typography>
                    <Typography
                        is="p"
                        size="m"
                        :class="$style.non_auth_description"
                    >
                        {{ t('page_description') }}
                    </Typography>
                    <GoogleAuth returnURL="/profile" />
                </section>
            </template>
            <DropCards :class="$style.drop_cards" />
            <PromoSignInVertical :class="$style.promo_mobile" />
            <PromoInviteVertical :class="$style.promo_mobile" />
            <PromoInviteHorizontal :class="$style.promo_tablet" />
            <ShopCards />
        </main>
    </Layout>
</template>
<script lang="ts" setup>
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import { onMounted } from 'vue'
import { useCategoriesStore } from '@/store/categories-store'
import Typography from '@/components_new/Typography.vue'
import { useI18n } from 'vue-i18n'
import ShopCards from '@/components_new/ShopCards/ShopCards.vue'
import { useUserStore } from '@/store/user-store/user-store'
import { storeToRefs } from 'pinia'
import PromoInviteHint from '@/components_new/PromoItems/components/PromoInviteHint.vue'
import PromoInviteVertical from '@/components_new/PromoItems/components/PromoInviteVertical.vue'
import PromoSignInVertical from '@/components_new/PromoItems/components/PromoSignInVertical.vue'
import GoogleAuth from '@/components_new/GoogleAuth.vue'
import DropCards from '@/components_new/DropCards/DropCards.vue'
import PromoInviteHorizontal from '@/components_new/PromoItems/components/PromoInviteHorizontal.vue'

const categoriesStore = useCategoriesStore()
const { t } = useI18n()

const userStore = useUserStore()
const { isAuthorized, user, isUserInfoLoaded } = storeToRefs(userStore)

onMounted(async () => {
    await categoriesStore.setMainData()
})
</script>
<style module>
.page {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.auth_title_page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.non_auth_title_page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 24px;
}

.non_auth_description {
    max-width: 665px;
}

.drop_cards {
    margin: 16px 0;
}

.promo_mobile {
    display: block;
}

.promo_tablet {
    display: none;
}

@media (--tablet-plus) {
    .promo_mobile {
        display: none;
    }

    .promo_tablet {
        display: block;
    }
}

@media (--desktop-small-plus) {
    .promo_tablet {
        display: none;
    }
}
</style>
<i18n lang="json">
{
    "en": {
        "page_title_auth": "Shop",
        "page_title_non_auth": "Playgama shop",
        "page_description": "Welcome to our gaming shop, where you can spend your earned coins on exciting prizes and exclusive gifts! Whether you’re after rare collectibles or fun surprises, our store offers a wide range of rewards to enhance your gaming experience."
    }
}
</i18n>
