import { GameEvent } from '@/types'
import { AdMediator } from '../adv-service/ad-mediator'
import { AdvAction } from '../adv'

const VERSION = 1
const SOURCE = 'GameEvents'

function generateMessageId() {
    return `${Date.now()}-${Math.random()}`
}
export enum MessageAction {
    pong = 'pong',
    unknownMessageAction = 'unknownMessageAction',
    unknownMessageType = 'unknownMessageType',
}

type OutcomingMessage = {
    action: MessageAction | AdvAction
    payload?: unknown
    type?: 'adv' | 'liveness' | 'error'
    originalMessageEvent: GameEvent
}

export type MainFrameOptions = {
    iframeElement?: HTMLIFrameElement
    prerollDisabled?: boolean
    adMediator: AdMediator
}

function sendMessage({ action, originalMessageEvent, payload, type }: OutcomingMessage) {
    console.info(action, type, payload)
    originalMessageEvent.source.postMessage(
        {
            action,
            id: generateMessageId(),
            payload: payload || {},
            responseToId: originalMessageEvent.data.id,
            source: SOURCE,
            type: type || originalMessageEvent.data.type,
            ver: VERSION,
        },
        // @ts-ignore: Incorrect overload for postMessage in lib.dom.d.ts
        '*',
    )
}

function adMediatorCallback(event: Event & { detail?: unknown }) {
    if (event.detail) {
        sendMessage(event.detail as OutcomingMessage)
    }
}

export class MainFrameService {
    private readonly bindedMessageHandler

    private readonly bindedFocusGameFrame

    private isPrerollShowed = false

    private adMediator: AdMediator

    private iframeElement: HTMLIFrameElement | undefined

    private prerollDisabled: boolean

    private bindedStickyRecalculateBanner?: (event: ScreenOrientationEventMap['change']) => void

    constructor(options: MainFrameOptions) {
        this.bindedMessageHandler = this.messageHandler.bind(this)
        this.bindedFocusGameFrame = this.focusGameFrame.bind(this)
        this.iframeElement = options.iframeElement
        this.prerollDisabled = !!options.prerollDisabled
        window.addEventListener('message', this.bindedMessageHandler)
        this.adMediator = options.adMediator
        this.adMediator.eventBus.addEventListener('adClose', this.bindedFocusGameFrame)
        this.adMediator.eventBus.addEventListener('adMessage', adMediatorCallback)
    }

    destroy() {
        window.removeEventListener('message', this.bindedMessageHandler)
        this.adMediator.eventBus.removeEventListener('adMessage', adMediatorCallback)
        this.adMediator.eventBus.removeEventListener('adClose', this.bindedFocusGameFrame)
        if (this.bindedStickyRecalculateBanner) {
            window.screen.orientation.removeEventListener('change', this.bindedStickyRecalculateBanner)
        }
    }

    messageHandler(event: MessageEvent) {
        if (event.data.source !== SOURCE || !event.source) {
            return
        }

        const { action, type } = event.data

        console.info(action, type)

        switch (type) {
            case 'liveness':
                sendMessage({
                    action: MessageAction.pong,
                    originalMessageEvent: event as GameEvent,
                })
                if (!this.prerollDisabled && !this.isPrerollShowed) {
                    this.adMediator.showPreroll()
                    this.isPrerollShowed = true
                }
                break

            case 'adv':
                this.adMediator.handleMessage(event as GameEvent)
                break

            default:
                sendMessage({
                    action: MessageAction.unknownMessageType,
                    originalMessageEvent: event as GameEvent,
                    payload: {
                        error: `Unknown message type "${type}"`,
                    },
                    type: 'error',
                })
                break
        }
    }

    private focusGameFrame() {
        if (this.iframeElement && this.iframeElement.contentWindow) {
            this.iframeElement.contentWindow.focus()
        }
    }
}
