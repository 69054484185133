<template>
    <HeadMetaGame />
    <Layout>
        <main :class="$style.page">
            <div>
                <GameContainer
                    :game="game"
                    :isGameStarted="isGameStarted"
                    @start-playing-game="startGame"
                    @open-report-modal="setIsReportBugModalOpen(true)"
                />
                <GameBreadcrumbs :game="game" />
            </div>
            <GameCardCarousel
                v-if="showRelatedGames"
                :title="t('relatedGames')"
                :games="relatedGames"
                @click="gamesClickHandler('related')"
            />
            <AdBlock
                type="leaderboard"
                :class="$style.tabletPlus"
                :emptyClass="$style.emptyAd"
            />
            <GameDetails
                v-if="game"
                :game="game"
            />
            <GameCardCarousel
                v-if="showSpotlightGames"
                :title="t('spotlight')"
                :games="spotlightGames"
                big
                @click="gamesClickHandler('related')"
            />
            <GameCardCarousel
                v-if="showRecommendedGames"
                :title="t('recommendedGames')"
                :games="recommendedGames"
                @click="gamesClickHandler('recommended')"
            />
            <GameCardCarousel
                v-if="showRandomGames"
                :title="t('random')"
                :games="randomGames"
                @click="gamesClickHandler('random')"
            />
            <PromoCategories />
            <TheEnd />
            <ModalReportBug
                :isOpen="isReportModalOpen"
                :page="route.fullPath"
                @close-report-modal="setIsReportBugModalOpen(false)"
            />
        </main>
    </Layout>
</template>
<script lang="ts" setup>
import { onServerPrefetch, onMounted, onBeforeUnmount, ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { categoriesTranslation } from '@/utils/translations/categories'
import { type PageStatus, useAppStore } from '@/store/app-store'
import { useCategoriesStore } from '@/store/categories-store'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import TheEnd from '@/components_new/TheEnd/TheEnd.vue'
import GameCardCarousel from '@/components_new/GameCardCarousel.vue'
import PromoCategories from '@/components_new/PromoCategories/PromoCategories.vue'
import AdBlock from '@/components/AdBlock.vue'
import { useLoggerStore } from '@/store/logger-store'
import { useLayoutStore } from '@/store/layout-store'
import ModalReportBug from '@/components_new/ModalItems/components/ModalReportBug.vue'
import HeadMetaGame from '@/pages/Games/HeadMetaGame.vue'
import { useGamePageStore } from './game-store'
import GameContainer from './components/GameContainer.vue'
import GameBreadcrumbs from './components/GameBreadcrumbs.vue'
import GameDetails from './components/GameDetails.vue'

const categoriesStore = useCategoriesStore()
const gamePageStore = useGamePageStore()
const appStore = useAppStore()
const route = useRoute()
const loggerStore = useLoggerStore()

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})
const { game, recommendations } = storeToRefs(gamePageStore)
const layoutStore = useLayoutStore()
const { setIsReportBugModalOpen } = layoutStore

const isGameStarted = ref(false)

const isReportModalOpen = computed(() => layoutStore.isReportBugModalOpen)
const relatedGames = computed(() => recommendations.value?.related)
const showRelatedGames = computed(() => (relatedGames.value ? relatedGames.value.length > 0 : true))
const spotlightGames = computed(() => recommendations.value?.top_playgama)
const showSpotlightGames = computed(() => (spotlightGames.value ? spotlightGames.value.length > 0 : true))
const recommendedGames = computed(() => recommendations.value?.recommended)
const showRecommendedGames = computed(() => (recommendedGames.value ? recommendedGames.value.length > 0 : true))
const randomGames = computed(() => recommendations.value?.random)
const showRandomGames = computed(() => (randomGames.value ? randomGames.value.length > 0 : true))

function gamesClickHandler(type: string) {
    loggerStore.logEvent({
        event: 'custom_event',
        eventName: 'game-navigation',
        label: type,
        action: 'click',
    })
}

function startGame() {
    isGameStarted.value = true
}

async function updateData(routeParams: string) {
    // Prevents the request from being made twice if the data is already in the store
    if (game.value?.hru !== routeParams) {
        isGameStarted.value = false
        gamePageStore.resetGameData()
        await gamePageStore.setGameWithRecommendations(routeParams)
    }
}

function ping() {
    if (!document.hidden && isGameStarted.value) {
        loggerStore.logEvent({
            event: 'custom_event',
            eventName: 'ping',
            gameHru: game.value?.hru,
            label: '',
            action: '',
            pageName: route.name as string,
        })
    }
}

onServerPrefetch(async () => {
    try {
        await Promise.all([updateData(route.params.game as string), categoriesStore.setMainData()])
        if (game.value?.id === route.params.game && route.params.game !== game.value?.hru) {
            const search = route.fullPath.replace(/^[^?]+/i, '')
            appStore.setPageStatus(301)
            appStore.setTargetURL(`/game/${game.value.hru}${search}`)
        }
    } catch (e) {
        if (typeof e === 'number') {
            throw new Error(e as PageStatus)
        } else {
            throw new Error(503)
        }
    }
})

const pingIntervalTimeOutMs = Number(process.env.VUE_APP_GAME_PING_INTERVSAL_MS) || 15000
const pingIntervalRef = ref<ReturnType<typeof setInterval> | null>(null)

onMounted(async () => {
    await Promise.all([updateData(route.params.game as string), categoriesStore.setMainData()])

    pingIntervalRef.value = setInterval(() => {
        ping()
    }, pingIntervalTimeOutMs)
})

onBeforeUnmount(() => {
    gamePageStore.resetGameData()
    clearInterval(pingIntervalRef.value || 0)
})
</script>
<style module>
.page {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

/*
    Style for empty Ad block.
    Cannot not be just "display: none" because Ad container needs to be visible to react on size change.
*/
.emptyAd {
    margin-top: -24px;
}

.tabletPlus {
    display: none;
}

@media (--tablet-plus) {
    .tabletPlus {
        display: block;
    }
}
</style>
<i18n lang="json">
{
    "en": {
        "relatedGames": "Related",
        "spotlight": "Spotlight",
        "recommendedGames": "Recommended",
        "random": "Random"
    }
}
</i18n>
