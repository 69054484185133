import { ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { useMotionProperties, useSpring } from '@vueuse/motion'

export const useDynamicHover = () => {
    const itemDynamicHover = ref<HTMLElement | null>(null)

    const { motionProperties } = useMotionProperties(itemDynamicHover, {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    })

    const { set, stop } = useSpring(motionProperties, {
        duration: 300,
        bounce: 0.5,
    })

    const updateItemDynamicHoverPosition = ({
        pageX,
        pageY,
        currentTarget,
    }: {
        pageX?: number
        pageY?: number
        currentTarget: HTMLElement
    }) => {
        const itemHover = itemDynamicHover.value
        if (!itemHover) {
            return
        }
        const currentTargetRect = currentTarget.getBoundingClientRect()
        const targetCenterX = currentTarget.clientWidth / 2
        const targetCenterY = currentTarget.clientHeight / 2
        let x = currentTarget.offsetLeft
        let y = currentTarget.offsetTop
        if (pageX && pageY) {
            x = currentTarget.offsetLeft + (pageX - currentTargetRect.left - targetCenterX) / (targetCenterX / 15)
            y = currentTarget.offsetTop + (pageY - currentTargetRect.top - targetCenterY) / (targetCenterY / 1.5)
        }
        stop()
        set({
            x,
            y,
            width: currentTarget.clientWidth,
            height: currentTarget.clientHeight,
        })
    }

    const updateItemDynamicHoverPositionDebounced = useDebounceFn(updateItemDynamicHoverPosition, 100)

    return {
        itemDynamicHover,
        updateItemDynamicHoverPosition,
        updateItemDynamicHoverPositionDebounced,
    }
}
