<template>
    <section>
        <Typography
            is="h2"
            type="header"
            size="m"
            :class="$style.title"
        >
            <span :class="$style.title__desktop">{{ t('title_desktop') }}</span>
            <span :class="$style.title__mobile">{{ t('title_mobile') }}</span>
        </Typography>
        <ul :class="$style.shop_cards">
            <li
                v-for="card in SHOP_CARDS"
                :key="card.id"
                :class="[$style.shop_cards__item, $style[card.id]]"
            >
                <img
                    :src="card.src"
                    :alt="card.title"
                />
            </li>
        </ul>
        <img
            :class="$style.pic_stay_tuned"
            :alt="t('alt_stay_tuned')"
            :src="StayTunedImg"
        />
    </section>
</template>

<script setup lang="ts">
import Typography from '@/components_new/Typography.vue'
import { useI18n } from 'vue-i18n'
import StayTunedImg from '@/components_new/ShopCards/images/stay_tuned.svg'
import AppleLogo from '@/components_new/ShopCards/images/apple.svg'
import SteamLogo from '@/components_new/ShopCards/images/steam.svg'
import NintendoLogo from '@/components_new/ShopCards/images/nintendo.svg'
import PlaystationLogo from '@/components_new/ShopCards/images/playstation.svg'
import XboxLogo from '@/components_new/ShopCards/images/xbox.svg'
import BlizzardLogo from '@/components_new/ShopCards/images/blizzard.svg'
import LegendsLogo from '@/components_new/ShopCards/images/legends.svg'
import PubgLogo from '@/components_new/ShopCards/images/pubg.svg'
import EALogo from '@/components_new/ShopCards/images/ea.svg'

const SHOP_CARDS = [
    {
        title: 'Steam',
        id: 'steam',
        src: SteamLogo,
    },
    {
        title: 'Nintendo',
        id: 'nintendo',
        src: NintendoLogo,
    },
    {
        title: 'Play Station',
        id: 'playstation',
        src: PlaystationLogo,
    },
    {
        title: 'Xbox',
        id: 'xbox',
        src: XboxLogo,
    },
    {
        title: 'Blizzard',
        id: 'blizzard',
        src: BlizzardLogo,
    },
    {
        title: 'League of Legends',
        id: 'league',
        src: LegendsLogo,
    },
    {
        title: 'PUBG mobile',
        id: 'pubg',
        src: PubgLogo,
    },
    {
        title: 'EA games',
        id: 'ea',
        src: EALogo,
    },
    {
        title: 'Apple',
        id: 'apple',
        src: AppleLogo,
    },
]
const { t } = useI18n()
</script>

<style module>
.title {
    margin-bottom: 16px;
}

.title__desktop {
    display: none;
}

.title__mobile {
    display: inline;
}

.shop_cards {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    position: relative;
}

.shop_cards:before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, #000);
    z-index: 1;
}

.shop_cards__item {
    border-radius: 12px;
    background-color: #17171a;
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    aspect-ratio: 220/132;
}
.shop_cards__item img {
    display: block;
    height: auto;
    margin: 10px;
    object-fit: contain;
}

.shop_cards li:not(:first-child) {
    margin-top: -128px;
}

.shop_cards__item.steam {
    background-color: #29292e;
}

.shop_cards__item.steam img {
    width: 134px;
}

.shop_cards__item.nintendo {
    background-color: #e60012;
}

.shop_cards__item.nintendo img {
    width: 146px;
}

.shop_cards__item.playstation {
    background: linear-gradient(180deg, #0262ac 0%, #054394 100%);
}

.shop_cards__item.playstation img {
    width: 224px;
}

.shop_cards__item.xbox {
    background-color: #107c0f;
}

.shop_cards__item.xbox img {
    width: 40px;
}

.shop_cards__item.blizzard {
    background-color: #000000;
}

.shop_cards__item.blizzard img {
    width: 76px;
}

.shop_cards__item.league {
    background-color: #110e1f;
}

.shop_cards__item.league img {
    width: 103px;
}

.shop_cards__item.pubg {
    background-color: rgba(255, 255, 255, 0.8);
}

.shop_cards__item.pubg img {
    width: 54px;
}

.shop_cards__item.ea {
    background-color: #285ca7;
}

.shop_cards__item.ea img {
    width: 61px;
}

.shop_cards__item.apple {
    display: none;
}

.pic_stay_tuned {
    width: 265px;
    height: 120px;
    display: block;
    align-self: center;
    margin: 32px auto auto;
}

@media (--mobile-landscape), (--tablet-plus) {
    .shop_cards__item {
        width: 32%;
        align-items: center;
        justify-content: center;
    }

    .shop_cards li:not(:first-child) {
        margin-top: 0;
    }
    .shop_cards__item.apple {
        display: flex;
        background-color: rgba(255, 255, 255, 0.8);
    }

    .shop_cards__item.steam img,
    .shop_cards__item.nintendo img,
    .shop_cards__item.playstation img,
    .shop_cards__item.blizzard img,
    .shop_cards__item.league img,
    .shop_cards__item.pubg img,
    .shop_cards__item.ea img {
        width: 50%;
    }
    .shop_cards__item.xbox img,
    .shop_cards__item.apple img {
        width: 30%;
    }

    .title__desktop {
        display: inline;
    }

    .title__mobile {
        display: none;
    }
}
</style>
<i18n lang="json">
{
    "en": {
        "title_desktop": "Cards – soon",
        "title_mobile": "Cards • soon",
        "alt_stay_tuned": "Stay tuned"
    }
}
</i18n>
