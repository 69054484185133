import type { PlaygamaLoggerEventType } from '@/types'

const ANALYTICS_ENDPOINT = process.env.ANALYTICS_API_HOST
const ANALYTICS_PATH = `${ANALYTICS_ENDPOINT}/api/v1/events`

export async function sendEvent(event: PlaygamaLoggerEventType) {
    try {
        await fetch(ANALYTICS_PATH, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(event),
            credentials: 'include',
        })
    } catch {
        // 🤷‍♂️
    }
}
