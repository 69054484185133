<template>
    <Layout>
        <main :class="$style.page">
            <img
                :class="$style.imgFennec"
                alt="coins"
                :src="inviteFriendsImg"
            />
            <div :class="$style.title_page">
                <Typography
                    is="h1"
                    size="l"
                    :responsive="false"
                >
                    <p v-html="pageTitle" />
                </Typography>
                <Typography
                    size="l"
                    :responsive="false"
                    :class="$style.title_page_subtitle"
                >
                    {{ t('subtitle') }}
                </Typography>
            </div>
            <GoogleAuth :class="$style.button" />
        </main>
    </Layout>
</template>
<script setup lang="ts">
import { onServerPrefetch, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useCategoriesStore } from '@/store/categories-store'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import Typography from '@/components_new/Typography.vue'
import GoogleAuth from '@/components_new/GoogleAuth.vue'
import { useInviteStore } from './invite-store'
import inviteFriendsImg from './images/invite-friends.png'

const { t } = useI18n()
const $route = useRoute()

const categoriesStore = useCategoriesStore()
const inviteStore = useInviteStore()

onServerPrefetch(async () => {
    await categoriesStore.setMainData()
    await inviteStore.getInviterName($route.params.inviteCode as string)
})

onMounted(async () => {
    await categoriesStore.setMainData()
    if (!inviteStore.inviterName) {
        await inviteStore.getInviterName($route.params.inviteCode as string)
    }
    await inviteStore.setInviter($route.params.inviteCode as string)
})

const pageTitle = computed(() => {
    if (inviteStore.inviterName) {
        return t('invitedBy', { name: inviteStore.inviterName })
    }
    return t('invited')
})
</script>

<style module>
.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.title_page {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
}
.title_page_subtitle {
    color: rgba(255, 255, 255, 0.8);
}

.imgFennec {
    width: 240px;
    height: 240px;
    display: block;
    margin: 40px auto 0;
}

.button {
    margin: 20px 0 80px;
}
</style>

<i18n lang="json">
{
    "en": {
        "invitedBy": "{name}<br>invites you to join Playgama",
        "invited": "You were invited to join Playgama",
        "subtitle": "Join Playgama to grab your first 50 000 coins and start playing to earn real rewards!"
    }
}
</i18n>
