<template>
    <Typography
        is="h2"
        size="xs"
        active
        :responsive="false"
        :class="$style.tasks_completed"
    >
        <Icon
            name="check"
            :class="$style.check"
        />
        {{ t('tasks_completed') }}
    </Typography>
</template>
<script setup lang="ts">
import Icon from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
<style module>
.tasks_completed {
    display: flex;
    align-items: center;
    gap: 12px;
}

.check {
    color: white;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    background-color: #34a853;
    border-radius: 100%;
}
</style>
<i18n lang="json">
{
    "en": {
        "tasks_completed": "Daily missions completed!"
    }
}
</i18n>
