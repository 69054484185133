<template>
    <slot v-if="beenInCorrectOrientation" />
    <div
        v-if="shouldRotate"
        :class="$style.rotate"
    >
        <img
            :src="RotateIcon"
            :alt="t('rotate')"
            :class="$style.icon"
        />
        <Typography
            type="header"
            size="m"
            :class="$style.title"
        >
            {{ t('rotate') }}
        </Typography>
        <Typography
            type="paragraph"
            size="m"
            :class="$style.text"
        >
            {{ t('message', { orientation: props.orientation[0] }) }}
        </Typography>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAppStore } from '@/store/app-store'
import Typography from '@/components_new/Typography.vue'
import RotateIcon from './icons/rotate.svg'

type Orientation = 'horizontal' | 'vertical'
interface RotateMessageProps {
    orientation: Orientation[]
}

const props = defineProps<RotateMessageProps>()

const { t } = useI18n()

const { platform } = useAppStore()
const beenInCorrectOrientation = ref(false)

const shouldRotate = ref(false)

const portraitMediaQuery = ref<MediaQueryList | null>(null)

const onOrientationChange = () => {
    const screenOrientation = portraitMediaQuery.value?.matches ? 'vertical' : 'horizontal'

    if (props.orientation.includes(screenOrientation)) {
        beenInCorrectOrientation.value = true
        shouldRotate.value = false
    } else {
        shouldRotate.value = true
    }
}

onMounted(() => {
    if (platform !== 'desktop' && props.orientation.length !== 2) {
        portraitMediaQuery.value = window.matchMedia('(orientation: portrait)')
        portraitMediaQuery.value.addEventListener('change', onOrientationChange)
        onOrientationChange()
    } else {
        beenInCorrectOrientation.value = true
    }
})

onUnmounted(() => {
    portraitMediaQuery.value?.removeEventListener('change', onOrientationChange)
})
</script>

<style module>
.rotate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
}

.icon {
    max-width: 50%;
    max-height: 50%;
    margin-bottom: 32px;
}

.title {
    margin-bottom: 12px;
}

.text {
    color: rgba(255, 255, 255, 0.8);
}
</style>

<i18n lang="json">
{
    "en": {
        "rotate": "Rotate phone",
        "message": "The game supports only { orientation } orientation"
    }
}
</i18n>
