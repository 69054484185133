import { defineStore } from 'pinia'
import type { EventForLoggersType, CustomEventForGa, PlaygamaLoggerEventType, AvailablePGEvents } from '@/types'
import { useUserStore } from '@/store/user-store/user-store'
import { useAppStore } from '@/store/app-store'
import { sendEvent } from '@/utils/analytics'

export type EventData = {
    gameHru?: string
    clid?: string
    userId?: string
}

const KEY_EVENTS: { timeout: number; eventName: AvailablePGEvents }[] = [
    { timeout: 5 * 60 * 1000, eventName: 'timespent_5min' },
    { timeout: 10 * 60 * 1000, eventName: 'timespent_10min' },
    { timeout: 15 * 60 * 1000, eventName: 'timespent_15min' },
]

export const useLoggerStore = defineStore('logger', () => {
    async function getEventData(): Promise<EventData> {
        const userStore = useUserStore()
        const appStore = useAppStore()
        const user = await userStore.getUser()
        return {
            clid: appStore.clid,
            userId: user?._id,
        }
    }

    async function logEvent(event: EventForLoggersType): Promise<void> {
        const { clid, userId } = await getEventData()
        if (event.eventName !== 'game_opened' && event.eventName !== 'session_start') {
            const gaEvent: CustomEventForGa = {
                event: event.event,
                eventName: event.eventName,
                label: event.label,
                action: event.action,
            }
            if (event.clid) {
                gaEvent.clid = event.clid
            }
            window.dataLayer.push(gaEvent)
        }

        const analyticsEvent: PlaygamaLoggerEventType = {
            eventName: event.eventName,
            pageName: event.pageName,
            gameHru: event.gameHru,
            userId,
            clid,
        }
        sendEvent(analyticsEvent)
    }

    function startKeyEventsCountdown() {
        KEY_EVENTS.forEach(({ timeout, eventName }) => {
            setTimeout(() => {
                logEvent({
                    event: 'custom_event',
                    eventName,
                    label: '',
                    action: '',
                })
            }, timeout)
        })
    }

    return {
        logEvent,
        startKeyEventsCountdown,
    }
})
