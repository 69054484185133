<template>
    <Layout>
        <LimitedWidthLayout>
            <HeroImage
                v-if="pageData.imageType"
                :imageType="pageData.imageType"
            />
            <Typography
                :is="'div'"
                type="paragraph"
                size="m"
                responsive
                :class="$style.markdownWrapper"
            >
                <component :is="markup" />
            </Typography>

            <ContactForm
                v-if="pageData.withContactForm"
                v-model="formData"
                :class="$style.contactForm"
                :submitStatus="submitStatus"
                @form-reset="resetForm"
                @submit="handleSubmit"
            />
        </LimitedWidthLayout>
    </Layout>
</template>
<script lang="ts" setup>
import { useHead } from '@unhead/vue'
import { compiler as md, RuleType as MdRuleType } from 'markdown-to-jsx'
import { computed, h, onMounted, onServerPrefetch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import HeroImage from '@/components_new/HeroImage/HeroImage.vue'
import LimitedWidthLayout from '@/layouts/LimitedWidthLayout.vue'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import { useCategoriesStore } from '@/store/categories-store'
import Link from '@/components_new/Link.vue'
import Typography from '@/components_new/Typography.vue'
import ContactForm from '@/components_new/ContactForm/ContactForm.vue'
import { useContactForm } from '@/components_new/ContactForm/use-contact-form'
import privacy from './confidential/Privacy Policy.md'
import license from './license/License agreement.md'
import partners from './partners-api/partners-api.md'
import termsofuse from './termsofuse/Terms of Use.md'
import takedownNotice from './takedown-notice/takedown-notice.md'

const { t } = useI18n()
const route = useRoute()
const categoriesStore = useCategoriesStore()

const pageData = computed(() => {
    if (route.name === 'confidential') {
        return {
            src: privacy,
            imageType: 'person-in-a-flower-field' as const,
            pageMeta: {
                title: `${t('privacyMetaTitle')} 🔒`,
                meta: [
                    {
                        name: 'description',
                        content: t('privacyMetaDescription'),
                    },
                ],
            },
        }
    }
    if (route.name === 'termsofuse') {
        return {
            src: termsofuse,
            imageType: 'ping-pong-rocket' as const,
            pageMeta: {
                title: `${t('termsOfUseMetaTitle')} ⚖️`,
                meta: [
                    {
                        name: 'description',
                        content: t('termsOfUsDescription'),
                    },
                ],
            },
        }
    }

    if (route.name === 'license') {
        return {
            src: license,
            imageType: 'boring-docs-disk' as const,
            pageMeta: {
                title: `${t('licenseMetaTitle')} 📄`,
                meta: [
                    {
                        name: 'description',
                        content: t('licenseMetaDescription'),
                    },
                ],
            },
        }
    }
    if (route.name === 'partners-api') {
        // TODO: нужна ли тут картинка и есть ли дизайн?
        return {
            src: partners,
            imageType: 'gems-on-pillow' as const,
            pageMeta: {},
        }
    }

    if (route.name === 'takedown-notice') {
        return {
            src: takedownNotice,
            withContactForm: true,
            imageType: 'halved-apple' as const,
            pageMeta: {
                title: `${t('takedownNoticeTitle')}`,
                meta: [
                    {
                        name: 'description',
                        content: t('takedownNoticeDescription'),
                    },
                ],
            },
        }
    }

    return { src: '', pageMeta: {} }
})

const pageMeta = computed(() => pageData.value.pageMeta)
const src = computed(() => pageData.value.src)
useHead(pageMeta)

const additionalRequestParamsRef = computed(() => ({ page: route.fullPath }))
const { formData, submitStatus, resetForm, handleSubmit } = useContactForm({
    additionalRequestParamsRef,
})

const markup = computed(() =>
    md(src.value, {
        createElement: (tag, rawProps, ...children) => {
            const { className, ...props } = rawProps
            switch (tag) {
                case 'h1': {
                    return h(
                        Typography,
                        {
                            type: 'header',
                            size: 'l',
                            is: tag,
                            responsive: true,
                            ...props,
                        },
                        {
                            default: () => children,
                        },
                    )
                }
                case 'h2':
                case 'h3':
                case 'h4':
                case 'h5':
                case 'h6': {
                    return h(
                        Typography,
                        {
                            type: 'header',
                            size: 's',
                            is: tag,
                            responsive: true,
                            ...props,
                        },
                        {
                            default: () => children,
                        },
                    )
                }
                case 'code': {
                    return h(
                        Typography,
                        {
                            type: 'paragraph',
                            size: 's',
                            is: tag,
                            responsive: true,
                            ...props,
                        },
                        {
                            default: () => children,
                        },
                    )
                }
                case 'a': {
                    return h(
                        Link,
                        { to: props.href, color: 'purple-600', target: props.target || '_blank' },
                        {
                            default: () => children,
                        },
                    )
                }
                default:
                    return h(tag, props, children)
            }
        },
        // Using renderRule for code blocks only, because need to know original AST node type, to conditionally render inline and block code.
        // TODO maybe get rid of createElement above completely?
        renderRule: (next, node /* , renderChildren, state */) => {
            switch (node.type) {
                case MdRuleType.codeBlock: {
                    return h(
                        'pre',
                        h(
                            Typography,
                            {
                                type: 'paragraph',
                                size: 'xs',
                                is: 'code',
                                responsive: true,
                            },
                            {
                                default: () => node.text,
                            },
                        ),
                    )
                }
                case MdRuleType.codeInline: {
                    return h(
                        Typography,
                        {
                            type: 'paragraph',
                            size: 's',
                            is: 'code',
                            responsive: true,
                        },
                        {
                            default: () => node.text,
                        },
                    )
                }
                default:
                    return next()
            }
        },
    }),
)

onServerPrefetch(async () => {
    await categoriesStore.setMainData()
})

onMounted(async () => {
    await categoriesStore.setMainData()
})
</script>
<style module>
.markdownWrapper p {
    margin-bottom: var(--gap-regular);
}

.markdownWrapper li {
    margin-bottom: var(--gap-small);
}

.markdownWrapper li p {
    margin: 0;
}

.markdownWrapper blockquote {
    background-color: rgba(255, 255, 255, 0.1);
    margin: 0 0 var(--gap-large) 0;
    padding: var(--gap-large);
    border-radius: var(--radius-large);
}

.markdownWrapper hr {
    margin: 40px 0;
    border: none;
    border-top: 1px solid var(--white-25);
}

.markdownWrapper code {
    font-family: monospace; /* TODO move to Typography */
    background-color: rgba(255, 255, 255, 0.1);
    display: inline-block;
    padding: 0 5px;
}

.markdownWrapper pre {
    margin: 0;
}

.markdownWrapper pre code {
    padding: var(--gap-large) var(--gap-medium);
    display: block;
    border-radius: var(--radius-medium);
    white-space: pre-wrap;
    margin-bottom: var(--gap-large);
}

.markdownWrapper h1 {
    padding-bottom: 40px;
    margin-top: var(--gap-large);
    margin-bottom: 40px;
    border-bottom: 1px solid var(--new-white-16);
    text-align: center;
}

.markdownWrapper h2,
.markdownWrapper h3 {
    margin-top: 40px;
    margin-bottom: var(--gap-large);
}

.markdownWrapper table {
    border-collapse: collapse;
    width: 100%;
    padding: var(--gap-large);
    border-radius: var(--radius-large);
    margin-bottom: var(--gap-large);
}

.markdownWrapper th {
    text-align: left;
}

.markdownWrapper tr td,
.markdownWrapper tr th {
    border-top: 1px solid var(--white-25);
    border-bottom: 1px solid var(--white-25);
    padding: var(--gap-regular) var(--gap-small) var(--gap-regular) 0;
}

.markdownWrapper tr td:last-child {
    padding-right: 0;
}

.markdownWrapper img {
    max-width: 100%;
}

.markdownWrapper ul {
    list-style-type: disc;
    margin: var(--gap-regular) 0;
    padding: 0 var(--gap-large);
    border-radius: var(--radius-large);
}

.markdownWrapper ul li {
    padding: 0;
}

.markdownWrapper table ul {
    word-break: break-word;
}

.contactForm {
    margin: 48px 0;
}
</style>
<i18n lang="json">
{
    "en": {
        "privacyMetaTitle": "Privacy Policy | Playgama",
        "privacyMetaDescription": "Learn about Playgama's commitment to protecting your privacy and personal information. Read our privacy policy.",
        "termsOfUseMetaTitle": "Terms of Use | Playgama",
        "termsOfUsDescription": "Review the terms and conditions for using Playgama's services. Understand your rights and responsibilities.",
        "licenseMetaTitle": "License Agreement | Playgama",
        "licenseMetaDescription": "Read the license agreement for using Playgama's games and services. Ensure compliance and understanding.",
        "takedownNoticeTitle": "Takedown Notice | Playgama",
        "takedownNoticeDescription": "Learn about Playgama's commitment to fostering secure, transparent and legally compliant environment for all our developers, users and partners."
    }
}
</i18n>
