import { GameProviders } from '@/types'

type ConfigKeys = GameProviders.GAME_DISTRIBUTION | GameProviders.GOOGLE_AD | GameProviders.DEBUG_PROVIDER

export type AdProviderType = {
    adProvider: GameProviders
    fallback: GameProviders | null
}

export const config: Record<ConfigKeys, AdProviderType> = {
    [GameProviders.GAME_DISTRIBUTION]: {
        adProvider: GameProviders.GAME_DISTRIBUTION,
        fallback: null,
    },
    [GameProviders.GOOGLE_AD]: {
        adProvider: GameProviders.GOOGLE_AD,
        fallback: GameProviders.YANDEX_AD,
    },
    [GameProviders.DEBUG_PROVIDER]: {
        adProvider: GameProviders.DEBUG_PROVIDER,
        fallback: null,
    },
}
