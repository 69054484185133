import { UserTasksStats } from '@/types'
import { request } from '@/utils/requests/request'

export async function claimTask(taskId: string): Promise<UserTasksStats> {
    const URL = '/api/v1/p2e/claim'
    try {
        const { originalResponse, data } = await request<UserTasksStats>(URL, {
            method: 'post',
            body: JSON.stringify({
                task_id: taskId,
            }),
        })
        if (!originalResponse.ok) {
            // возможно логировать причину - непонятно
            return Promise.reject(originalResponse.status)
        }
        return data
    } catch (error) {
        console.error({ URL, error })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(500)
    }
}

export async function getTasks(): Promise<UserTasksStats> {
    const URL = '/api/v1/p2e/current'

    try {
        const { originalResponse, data } = await request<UserTasksStats>(URL)

        if (!originalResponse.ok) {
            // возможно логировать причину - непонятно
            return Promise.reject(originalResponse.status)
        }

        return data
    } catch (error) {
        console.error({ URL, error })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(500)
    }
}
