<template>
    <Layout>
        <main :class="$style.container">
            <CategoryHeader :category="category" />
            <div :class="$style.pagesAndSeo">
                <SeoText
                    v-if="category?.seoText"
                    :content="category.seoText"
                />
                <div :class="$style.pages">
                    <template
                        v-for="(page, index) in pages"
                        :key="index"
                    >
                        <div
                            v-if="page instanceof Array || showPlaceholders"
                            :id="`page-${index + 1}`"
                            v-intersection-observer="onPageIntersection"
                            :data-page-number="index + 1"
                        >
                            <div :class="$style.grid">
                                <template v-if="page instanceof Array">
                                    <template
                                        v-for="(game, idx) in page"
                                        :key="game.hru"
                                    >
                                        <GameCard
                                            :class="[bigCards.includes(idx) ? $style.bigCard : '']"
                                            :game="game"
                                            :appearance="bigCards.includes(idx) ? 'big' : 'medium'"
                                            responsive
                                            targetLink="_blank"
                                        />
                                    </template>
                                </template>
                                <template v-else>
                                    <template
                                        v-for="(_, idx) in placeholderPage"
                                        :key="`placeholder-${idx}`"
                                    >
                                        <GameCard
                                            :class="[{ [$style.bigCard]: bigCards.includes(idx) }]"
                                            :game="undefined"
                                            :appearance="bigCards.includes(idx) ? 'big' : 'medium'"
                                            responsive
                                        />
                                    </template>
                                </template>
                            </div>
                        </div>
                        <template v-if="index === 0">
                            <AdBlock
                                type="leaderboard"
                                :class="$style.tabletPlus"
                                :emptyClass="$style.emptyAd"
                            />
                            <AdBlock
                                type="catalog_mobile"
                                :class="$style.mobile"
                                :emptyClass="$style.emptyAd"
                            />
                        </template>
                        <PromoCategories
                            v-if="index === 1"
                            :class="$style.promo"
                        />
                        <template v-if="index > 1">
                            <AdBlock
                                type="leaderboard_rest"
                                :class="$style.tabletPlus"
                                :emptyClass="$style.emptyAd"
                                intersectionRootMargin="100px 0px"
                            />
                            <AdBlock
                                type="catalog_mobile_rest"
                                :class="$style.mobile"
                                :emptyClass="$style.emptyAd"
                                intersectionRootMargin="100px 0px"
                            />
                        </template>
                    </template>
                    <div
                        v-if="showPlaceholders && nextPagePlaceholder"
                        v-intersection-observer="onPageIntersection"
                        :data-page-number="pages.length + 1"
                    >
                        <div :class="$style.grid">
                            <template
                                v-for="(_, index) in placeholderPage"
                                :key="`placeholder-${index}`"
                            >
                                <GameCard
                                    :class="[{ [$style.bigCard]: bigCards.includes(index) }]"
                                    :game="undefined"
                                    :appearance="bigCards.includes(index) ? 'big' : 'medium'"
                                    responsive
                                />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <TheEnd targetUrl="_blank" />
        </main>
    </Layout>
</template>
<script setup lang="ts">
import { onServerPrefetch, onMounted, computed, watch, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import { vIntersectionObserver } from '@vueuse/components'

import { PageStatus } from '@/store/app-store'
import Layout from '@/layouts/ModernTwoColumnsLayout.vue'
import TheEnd from '@/components_new/TheEnd/TheEnd.vue'
import GameCard from '@/components_new/GameCard.vue'
import PromoCategories from '@/components_new/PromoCategories/PromoCategories.vue'
import { useCategoriesStore } from '@/store/categories-store'
import AdBlock from '@/components/AdBlock.vue'
import SeoText from '@/components_new/SeoText.vue'
import { useCategoryPageStore } from './category-page-store'
import CategoryHeader from './components/CategoryHeader.vue'

const categoriesStore = useCategoriesStore()
const categoryPageStore = useCategoryPageStore()

const { t } = useI18n()

const { pages, pageSize, category } = storeToRefs(categoryPageStore)
const placeholderPage = computed(() => Array(pageSize.value).fill(null))
const nextPagePlaceholder = computed(() => {
    if (category.value) {
        return pages.value.length < category.value.totalPages
    }
    return true
})

const $route = useRoute()
const router = useRouter()

function onPageIntersection([{ isIntersecting, target }]: IntersectionObserverEntry[]) {
    const pageIndexString = (target as HTMLElement).dataset.pageNumber
    if (!pageIndexString) {
        return
    }
    const pageIndex = parseInt(pageIndexString, 10)
    if (isIntersecting) {
        router.replace({
            // pass optional path to prevent vue router from adding/removing final slash
            path: $route.path,
            query: {
                ...$route.query,
                page: pageIndex > 1 ? pageIndex : undefined,
            },
        })
    }
}

const activePage = computed(() => parseInt($route.query.page as string, 10) || 1)

function routeChanged(prefetchNextPage = false) {
    const categoryName = $route.params.category as string
    const page = parseInt($route.query.page as string, 10) || 1
    const mainRequest = categoryPageStore.setCategory({
        categoryName,
        page,
    })

    if (prefetchNextPage) {
        categoryPageStore.setCategory({
            categoryName,
            page: page + 1,
        })
    }

    return mainRequest
}

function getMetaURL(id: string, page: number) {
    return `${process.env.VUE_APP_HOST_URL}/category/${id}${page > 1 ? `?page=${page}` : ''}`
}

const pageMeta = computed(() => {
    if (!category.value || !category.value.id) {
        return {}
    }

    const categoryId = category.value.id

    const links = [
        {
            rel: 'canonical',
            href: () => getMetaURL(categoryId, activePage.value),
        },
    ]

    if (activePage.value > 1) {
        links.push({
            rel: 'prev',
            href: () => getMetaURL(categoryId, activePage.value - 1),
        })
    }
    if (activePage.value < category.value.totalPages) {
        links.push({
            rel: 'next',
            href: () => getMetaURL(categoryId, activePage.value + 1),
        })
    }

    const pageStr = activePage.value > 1 ? ` | ${t('page', { page: activePage.value })}` : ''

    return {
        title: () => `${category.value?.metaTitle}${pageStr}`,
        meta: [
            {
                name: 'title',
                content: `${category.value?.metaTitle}${pageStr}`,
            },
            {
                name: 'description',
                content: `${category.value?.metaDescription}${pageStr}`,
            },
            {
                property: 'og:title',
                content: `${category.value?.metaTitle}${pageStr}`,
            },
            {
                property: 'og:description',
                content: `${category.value?.metaDescription}${pageStr}`,
            },
            {
                name: 'twitter:title',
                content: `${category.value?.metaTitle}${pageStr}`,
            },
            {
                name: 'twitter:description',
                content: `${category.value?.metaDescription}${pageStr}`,
            },
        ],
        link: links,
    }
})

useHead(pageMeta)

watch(
    () => $route.params.category,
    () => routeChanged(true),
)

watch(
    () => $route.query.page,
    () => routeChanged(true),
)

// depends on the pageSize, to make cards fill all the space without gaps
// on 2, 3, 4, 5, 6 columns
const bigCards = ref<number[]>([0, 13, 14, 25])

onServerPrefetch(async () => {
    try {
        await Promise.all([routeChanged(), categoriesStore.setMainData()])
    } catch (e) {
        if (typeof e === 'number') {
            throw new Error(e as PageStatus)
        } else {
            throw new Error(500)
        }
    }
})

const showPlaceholders = ref(false)

onMounted(async () => {
    showPlaceholders.value = true
    if (category.value?.id !== $route.params.category) {
        routeChanged()
    }
    await categoriesStore.setMainData()
})
</script>
<style module>
.container {
    --pages-gap: 8px;
    --min-card-width: 180px;
}

.container,
.pagesAndSeo,
.pages {
    display: flex;
    flex-direction: column;
    gap: var(--pages-gap);
}

/*
    Style for empty Ad block.
    Cannot not be just "display: none" because Ad container needs to be visible to react on size change.
*/
.emptyAd {
    margin-top: calc(var(--pages-gap) * -1);
}

.pagesAndSeo {
    flex-direction: column-reverse;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--min-card-width), 1fr));
    grid-auto-flow: dense;
    gap: 8px;
    --cards-gap: 8; /* unitless, consumed by GameCard component */
    position: relative;
}

.bigCard {
    grid-column: span 2;
    grid-row: span 2;
}

.promo {
    margin: calc(24px - var(--pages-gap)) 0;
}

.mobile,
.tabletPlus {
    display: none;
}

@media (--mobile) {
    .container {
        --min-card-width: auto;
    }

    .mobile {
        display: block;
    }
}

@media (--tablet-plus) {
    .tabletPlus {
        display: block;
    }
}
</style>
<i18n lang="json">
{
    "en": {
        "page": "page {page}"
    }
}
</i18n>
