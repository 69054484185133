<template>
    <ModalContainer
        :title="t('title')"
        :description="t('description')"
        :image="BugImg"
        :alt="t('alt')"
        :isOpened="isOpen"
        :class="$style.dialog"
        @close-modal="closeModal"
    >
        <template #custom-content>
            <ContactForm
                v-model="formData"
                viewMode="modal"
                :submitStatus="submitStatus"
                @form-reset="resetForm"
                @submit="handleSubmit"
            />
        </template>
    </ModalContainer>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useContactForm } from '@/components_new/ContactForm/use-contact-form'
import ContactForm from '@/components_new/ContactForm/ContactForm.vue'
import ModalContainer from '@/components_new/ModalItems/ModalContainer.vue'
import BugImg from '@/components_new/ModalItems/images/bug.png'

const { t } = useI18n()

const props = defineProps<{
    isOpen: boolean
    page: string
}>()

const emit = defineEmits<{ closeReportModal: [] }>()

const additionalRequestParamsRef = computed(() => ({ page: props.page }))

const closeModal = () => emit('closeReportModal')

const { formData, submitStatus, resetForm, handleSubmit } = useContactForm({
    additionalRequestParamsRef,
    onSuccessAnimationFinished: closeModal,
})
</script>

<style module>
.dialog {
    --dialog-width: 520px;
}
</style>

<i18n lang="json">
{
    "en": {
        "title": "Report a problem",
        "description": "Please provide as much information as possible",
        "close": "Close",
        "alt": "Image of Bug"
    }
}
</i18n>
