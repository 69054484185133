<template>
    <ErrorPage type="404" />
</template>
<script setup lang="ts">
import { onServerPrefetch } from 'vue'
import { useAppStore } from '@/store/app-store'
import ErrorPage from './ErrorPage.vue'

const appStore = useAppStore()

onServerPrefetch(() => {
    appStore.setPageStatus(404)
})
</script>
