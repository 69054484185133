<template>
    <form
        :class="[$style.form, { [$style.modal]: viewMode === 'modal' }]"
        @submit.prevent="emit('submit', formData)"
    >
        <!-- eslint-disable vuejs-accessibility/form-control-has-label -->
        <!-- we have labels inside the TextInput component -->
        <TextInput
            id="form-email"
            v-model="formData.email"
            required
            :disabled="areInputsDisabled"
            size="m"
            name="email"
            type="email"
            :labelText="t('email')"
            :placeholder="t('email')"
        />

        <TextInput
            is="textarea"
            id="form-message"
            v-model="formData.message"
            required
            :disabled="areInputsDisabled"
            withHiddenLabel
            name="message"
            :labelText="t('message')"
            :placeholder="t('message')"
        />

        <div :class="$style.submitContainer">
            <Button
                :class="[$style.submitBtn, buttonProps.class]"
                size="l"
                :color="buttonProps.color"
                :type="buttonProps.type"
                :iconLeft="buttonProps.iconLeft"
                :disabled="buttonProps.disabled"
                @click="buttonProps.onClick"
            >
                {{ buttonProps.title }}
            </Button>
            <Typography
                is="p"
                :size="viewMode === 'modal' ? 'xs' : 's'"
                :class="$style.submitHint"
            >
                <span :class="$style.mutedText">
                    {{ t('agreementTextStart') }}
                </span>
                <Link
                    to="/termsofuse"
                    color="purple-500"
                    target="_blank"
                >
                    {{ t('termsOfUse') }}
                </Link>
                <span :class="$style.mutedText">
                    {{ t('agreementTextAnd') }}
                </span>
                <Link
                    :to="PRIVACY_POLICY_NAVIGATION_ITEM.link"
                    color="purple-500"
                    target="_blank"
                >
                    {{ t(PRIVACY_POLICY_NAVIGATION_ITEM.title) }}
                </Link>
            </Typography>
        </div>
    </form>
</template>
<script setup lang="ts">
import { computed, useCssModule } from 'vue'
import { useI18n } from 'vue-i18n'
import { PRIVACY_POLICY_NAVIGATION_ITEM } from '@/constants/general'
import { FormSubmitData } from '@/types'
import { RequestStatus } from '@/utils/requests/types'
import { commonNavigationTranslation } from '@/utils/translations/common-navigation'
import Button from '@/components_new/Button.vue'
import Link from '@/components_new/Link.vue'
import TextInput from '@/components_new/TextInput/TextInput.vue'
import Typography from '@/components_new/Typography.vue'

const props = defineProps<{
    submitStatus: RequestStatus
    viewMode?: 'modal' | 'default'
}>()
const emit = defineEmits<{ submit: [formState: FormSubmitData]; successButtonClicked: []; formReset: [] }>()
const classes = useCssModule()
const formData = defineModel<FormSubmitData>({ required: true })

const hasSuccessfullySubmitted = computed(() => props.submitStatus === 'ok')
const isError = computed(() => props.submitStatus === 'error')
const isSending = computed(() => props.submitStatus === 'pending')
const areInputsDisabled = computed(() => isSending.value || hasSuccessfullySubmitted.value)
const hasMissingValues = computed(() => Object.values(formData.value).some((value) => !value.trim()))

const { t } = useI18n({
    messages: {
        en: {
            ...commonNavigationTranslation.en,
        },
    },
})

const buttonProps = computed(() => {
    if (hasSuccessfullySubmitted.value) {
        return {
            // successs button resets the form and is never disabled
            disabled: false,
            color: 'lime' as const,
            iconLeft: 'check' as const,
            title: t('messageSent'),
            type: undefined,
            onClick: () => emit('formReset'),
        }
    }

    return {
        disabled: areInputsDisabled.value || hasMissingValues.value,
        color: isError.value ? ('rose-400' as const) : ('purple-400' as const),
        type: 'submit' as const,
        title: isError.value ? t('error') : t('submit'),
        class: isError.value ? classes.shakeError : undefined,
    }
})
</script>

<style module>
.form {
    display: flex;
    flex-direction: column;
    gap: var(--new-gap-regular);
}

.mutedText {
    color: var(--new-white-40);
}

.submitContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: var(--new-gap-regular);
}

.submitBtn {
    flex-basis: 240px;
    width: 240px;
    flex-grow: 1;
}

.shakeError {
    animation: shake 0.15s ease-in-out 0s 2;
}

.submitHint {
    flex-grow: 1;
    text-align: center;
}

@media (--desktop-medium-plus) {
    .submitHint {
        flex-grow: 1;
        text-align: left;
    }
}

.modal .submitHint {
    text-align: center;
}

@keyframes shake {
    0% {
        transform: translate3d(0rem, 0, 0);
    }
    25% {
        transform: translate3d(10px, 0, 0);
    }
    75% {
        transform: translate3d(-10px, 0, 0);
    }
    100% {
        transform: translate3d(0rem, 0, 0);
    }
}
</style>

<i18n lang="json">
{
    "en": {
        "message": "Message",
        "submit": "Send",
        "messageSent": "Message was sent",
        "error": "Something went wrong",
        "email": "Email",
        "agreementTextStart": "By clicking \"Send\" I agree to the ",
        "agreementTextAnd": " and the ",
        "termsOfUse": "Terms of Use"
    }
}
</i18n>
