<template>
    <div :class="$style.row">
        <component
            :is="i === 0 ? 'div' : ContentSection"
            v-for="(section, i) in sections"
            :key="section.title"
            :class="[$style.section, $style.column]"
        >
            <Typography
                is="h2"
                size="xs"
            >
                {{ section.title }}
            </Typography>
            <ul>
                <li
                    v-for="(item, index) in section.items"
                    :key="index"
                    :class="[$style.list, $style.column]"
                >
                    <ListItem
                        :is="BaseLink"
                        :target="item.target"
                        :to="item.href"
                        :iconName="item.iconName"
                    >
                        <template
                            v-if="item.title"
                            #title
                        >
                            {{ item.title }}
                        </template>
                        <template #data>
                            {{ item.data }}
                        </template>
                    </ListItem>
                </li>
            </ul>
        </component>
    </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { IconNameType } from '@/components_new/Icon'
import BaseLink from '@/components_new/BaseLink.vue'
import ListItem from '@/components_new/ListItem/ListItem.vue'
import Typography from '@/components_new/Typography.vue'
import ContentSection from '@/pages/ContactUs/components/ContentSection.vue'

type Item = {
    title?: string
    data: string
    iconName?: IconNameType
    href: string
    target?: '_blank'
}

type Section = {
    title: string
    items: Item[]
}

const { t } = useI18n()

const sections = computed<Section[]>(() => [
    {
        title: t('Social'),
        items: [
            {
                data: '@playgama_dev_care',
                iconName: 'telegram',
                href: 'https://t.me/playgama_dev_care',
                target: '_blank',
            },
            {
                data: t('playgamaForDevs'),
                iconName: 'discord',
                href: 'https://discord.com/invite/pzqd2upxr8',
                target: '_blank',
            },
            {
                data: 'Playgama',
                iconName: 'linked_in',
                href: 'https://www.linkedin.com/company/playgama',
                target: '_blank',
            },
            {
                data: 'playgamagames',
                iconName: 'facebook',
                href: 'https://www.facebook.com/playgamagames',
                target: '_blank',
            },
        ],
    },
    {
        title: t('Email'),
        items: [
            {
                title: t('developers'),
                data: 'developer.success@playgama.com',
                href: 'mailto:developer.success@playgama.com',
                target: '_blank',
            },
            {
                title: t('support'),
                data: 'support@playgama.com',
                href: 'mailto:support@playgama.com',
                target: '_blank',
            },
            {
                title: t('media'),
                data: 'media@playgama.com',
                href: 'mailto:media@playgama.com',
                target: '_blank',
            },
        ],
    },
])
</script>

<style module>
.row {
    flex-direction: column;
    gap: 0;
}

@media (--desktop-small-plus) {
    .row {
        display: flex;
        flex-direction: row;
        gap: var(--gap-regular);
        flex-wrap: wrap;
    }

    .section.column {
        /* Removing ContentSection styles for Desktop */
        padding: 0;
        margin: 0;
        border: none;
    }
}

.column {
    display: flex;
    flex-direction: column;
}

.section {
    flex-grow: 1;
    gap: var(--gap-regular);
}

.list {
    gap: var(--gap-small);
}
</style>

<i18n lang="json">
{
    "en": {
        "telegram": "Telegram",
        "discord": "Discord",
        "linkedIn": "LinkedIn",
        "facebook": "Facebook",
        "developers": "Developers",
        "email": "Email",
        "support": "Support",
        "social": "Social",
        "media": "Media",
        "playgamaForDevs": "Playgama for Devs"
    }
}
</i18n>
