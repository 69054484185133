import { CategoriesType } from '@/types'
import { getCategoriesTexts } from './categories-texts'

export const categoriesTranslation: Record<string, Record<CategoriesType, string>> = {
    en: {
        recommended: 'Recommended',
        all_games: 'All games',
        trending_now: 'Trending now',
        new: 'New games',
        random: 'Random',
        popular: 'Popular',
        '2_player': '2 Player',
        '3d': '3D',
        action: 'Action',
        adventure: 'Adventure',
        arcade: 'Arcade',
        baby: 'Baby',
        bejeweled: 'Bejeweled',
        boys: 'Boys',
        clicker: 'Clicker',
        cooking: 'Cooking',
        farming: 'Farming',
        girls: 'Girls',
        hypercasual: 'Hypercasual',
        io: '.io',
        multiplayer: 'Multiplayer',
        puzzle: 'Puzzle',
        racing: 'Racing',
        shooting: 'Shooting',
        sports: 'Sports',
        stickman: 'Stickman',
        soccer: 'Soccer',
        social: 'Social',
        top_picks: 'Top picks',
        in_game_purchase: 'InGame purchase',
        cards: 'Cards',
        kids: 'Kids',
        educational: 'Educational',
        quiz: 'Quiz',
        simulator: 'Simulator',
        strategy: 'Strategy',
        tabletop: 'Board', // TODO rename whole category on backend
        match3: 'Match3',
        role: 'RPG',
        casino: 'Casino',
        balloons: 'Bubble Shooter', // TODO rename whole category on backend
        economic: 'Idle', // TODO rename whole category on backend
        midcore: 'Midcore',
        horrors: 'Horrors',
        imitations: 'Imitations',
        applications: 'Applications',
        tests: 'Tests',
    },
}
function getTranslation(obj: Record<CategoriesType, string>) {
    const keys = Object.keys(obj) as CategoriesType[]
    return keys.reduce(
        (acc, key) => {
            if (obj[key].includes('games')) {
                acc[key] = obj[key]
            } else {
                acc[key] = `${obj[key]} games`
            }
            return acc
        },
        {} as Record<CategoriesType, string>,
    )
}
export const categoriesFullNamesTranslation = {
    en: getTranslation(categoriesTranslation.en),
}

export const categoriesTexts = getCategoriesTexts(categoriesFullNamesTranslation.en)
