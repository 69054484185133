import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { CategoriesType, CategoryListType, PaginationType, SimplifiedGame } from '@/types'
import { ICONS_FOR_CATEGORIES } from '@/constants/general'
import { getGamesByCategories, getManyGamesData } from '@/utils/requests/games'
import { pickManyRandomElements, shuffle } from '@/utils/helpers'
import { useAppStore } from '@/store/app-store'

export const POPULAR_GAMES = [
    'roblox-obby-tower-of-hell',
    'magic-tiles-4',
    'obby-but-youre-on-a-bike',
    'brawl-box-stars-simulator',
    'draw-joust',
    'starr-drops',
    'faster-but-surely',
    'basket-random',
    'tiles-hop',
    'brawl-stars-mega-simulator',
    'bubble-pop-classic',
    'brawl-stars-lucky-box-simulator',
    'ball-sort-puzzle--color-sort',
    'granny-original',
    'ball-sort-puzzle',
    'capybara-evolution-clicker',
    'tap-wood-blocks-away',
    'wood-block-classic',
    'obby-tower-of-hell',
    'save-the-pets',
    'imposter-3d-online-horror',
    'geometry-dash-super-wave',
    'cs-go-parkour--bhop-and-surf-in-3d',
    'brick-breaker-chipi-chipi-chapa-chapa-cat',
    'table-tennis-world-tour',
    'noob-miner-2-escape-from-prison',
    'attack-hole',
    'noob-obby-on-a-bike',
    'noob-lumberjack',
    'geometry-dash-hardcore',
    'words-of-wonders#goog_game_inter',
]

const INTERNAL_CATEGORIY_TYPES: CategoriesType[] = [
    'popular',
    'all_games',
    'recommended',
    'trending_now',
    'new',
    'random',
]

export const useCategoriesStore = defineStore('categories', () => {
    const menuHighlightCategoriesList = ref<CategoryListType[]>([])
    const contentCategoriesList = ref<CategoryListType[]>([])
    const menuCategoriesList = ref<CategoryListType[]>([])
    const popularGames = ref<SimplifiedGame[]>([])
    const randomGames = ref<SimplifiedGame[]>([])
    const recommendedGames = ref<SimplifiedGame[] | null>(null)
    const randomGame = ref<SimplifiedGame | null>(null)

    const appStore = useAppStore()

    async function setCategories(pagination: PaginationType): Promise<void> {
        const currentPlatform = appStore.platform
        const data = await getGamesByCategories({ platform: currentPlatform, pagination })
        const notInternal: CategoryListType[] = []
        const internalCategories: CategoryListType[] = []
        const { categories } = data
        categories.forEach(({ id, games }) => {
            if (!games.length) {
                return
            }
            if (INTERNAL_CATEGORIY_TYPES.includes(id)) {
                if (id !== 'random') {
                    internalCategories.push({
                        name: id,
                        href: `/category/${id}`,
                        icon: ICONS_FOR_CATEGORIES[id],
                        games,
                    })
                } else {
                    randomGames.value = games
                }
            } else {
                notInternal.push({
                    name: id,
                    href: `/category/${id}`,
                    icon: ICONS_FOR_CATEGORIES[id],
                    games,
                })
            }
        })

        contentCategoriesList.value = [...internalCategories, ...notInternal]

        menuCategoriesList.value = notInternal

        menuHighlightCategoriesList.value = [
            {
                name: 'all_games',
                href: '/',
                icon: ICONS_FOR_CATEGORIES.all_games,
            },
            ...internalCategories,
        ].filter(Boolean) as CategoryListType[]
    }

    async function setPopularGames() {
        const popularGamesHru = pickManyRandomElements(POPULAR_GAMES, 9)
        const selectedPopularGames = await getManyGamesData(popularGamesHru)
        const selectedRandomGames = pickManyRandomElements(randomGames.value, 3)
        popularGames.value = [...selectedPopularGames, ...selectedRandomGames]
    }

    function updateRandomGame() {
        const currentIndex = randomGames.value.findIndex((game) => game.hru === randomGame.value?.hru) || 0
        const nextIndex = currentIndex + 1
        randomGame.value = randomGames.value[nextIndex % randomGames.value.length]
    }

    function shuffleRandomGames(): void {
        randomGames.value = shuffle(randomGames.value)
        updateRandomGame()
    }

    // For Easy shuffling on UI side - as we have cache on ssr we have to reshuffle after page rendered
    async function setMainData() {
        if (contentCategoriesList.value.length) {
            if (typeof window !== 'undefined') {
                shuffleRandomGames()
            }
        } else {
            await Promise.all([
                setCategories({
                    page: 1,
                    pageSize: 32,
                }),
                setPopularGames(),
            ])
            if (typeof window !== 'undefined' && contentCategoriesList.value.length) {
                shuffleRandomGames()
            }
        }
    }

    return {
        menuHighlightCategoriesList,
        menuCategoriesList,
        contentCategoriesList,
        popularGames,
        randomGames,
        recommendedGames,
        setMainData,
        randomGame,
        updateRandomGame,
    }
})
