<template>
    <div :class="$style.footer">
        <div :class="$style.links_group">
            <Typography
                is="ul"
                type="paragraph"
                size="xs"
                :class="$style.links"
            >
                <li>
                    <Link
                        to="/"
                        :class="$style.links_item"
                        color="white-60"
                    >
                        {{ copyright }}
                    </Link>
                </li>
            </Typography>
        </div>
        <div :class="$style.links_group">
            <Typography
                is="ul"
                type="paragraph"
                size="xs"
                :class="$style.links"
            >
                <li
                    v-for="(item, index) in NAVIGATION_LINKS"
                    :key="item.title"
                >
                    <span
                        v-if="index !== 0"
                        :class="$style.links_sep"
                    />
                    <Link
                        :to="item.link"
                        :target="item.target"
                        :class="$style.links_item"
                        color="white-60"
                    >
                        {{ t(item.title) }}
                    </Link>
                </li>
            </Typography>
        </div>
        <div :class="$style.links_group">
            <Typography
                is="h3"
                type="paragraph"
                size="m"
                :class="$style.links_title"
            >
                {{ t('categories_title') }}
            </Typography>
            <Typography
                is="ul"
                type="paragraph"
                size="xs"
                :class="$style.links"
            >
                <li
                    v-for="item in menuCategoriesList"
                    :key="item.title"
                >
                    <Link
                        :to="item.href"
                        :class="$style.links_item"
                        color="white-60"
                    >
                        {{ t(item.name) }}
                    </Link>
                </li>
            </Typography>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useCategoriesStore } from '@/store/categories-store'
import { categoriesTranslation } from '@/utils/translations/categories'
import { storeToRefs } from 'pinia'
import Typography from '@/components_new/Typography.vue'
import Link from '@/components_new/Link.vue'
import { commonNavigationTranslation } from '@/utils/translations/common-navigation'
import { NAVIGATION_LINKS } from '@/constants/general'

const { t } = useI18n({
    messages: {
        en: {
            ...categoriesTranslation.en,
            ...commonNavigationTranslation.en,
            copyright: '© Playgama, {year}',
            categories_title: 'Games Categories',
        },
    },
})

const categoriesStore = useCategoriesStore()

const { menuCategoriesList } = storeToRefs(categoriesStore)

const copyright = t('copyright', { year: new Date().getFullYear() })
</script>

<style module>
.footer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 24px;
    padding-top: 16px;
    padding-bottom: 40px;
}

.links_group {
    border-top: 1px solid var(--new-white-20);
    margin-top: 16px;
    padding-top: 16px;
}

.links {
    columns: 2 auto;
}

.links_title {
    display: block;
    margin-bottom: 12px;
    color: rgba(255, 255, 255, 0.6);
}

.links_item {
    display: block;
    padding: 4px 0;
}

@media (--tablet-plus) {
    .footer {
        display: block;
        border-top: 1px solid var(--new-white-20);
        columns: 6 auto;
        padding-top: 24px;
    }

    .links_group {
        border-top: none;
        margin-top: unset;
        padding-top: unset;
    }

    .links_title {
        display: none;
    }

    .links {
        columns: auto;
        break-after: column;
    }
}
</style>
