<template>
    <VTooltip
        theme="common-tooltip"
        :triggers="triggers"
        :shown="shown"
    >
        <slot />
        <template #popper>
            <Typography
                type="label"
                size="m"
                :accent="true"
                :responsive="false"
            >
                {{ props.content }}
            </Typography>
        </template>
    </VTooltip>
</template>
<script setup lang="ts">
import Typography from '@/components_new/Typography.vue'
import { computed } from 'vue'
import { TriggerEvent, Tooltip as VTooltip } from 'floating-vue'

interface TooltipProps {
    content?: string
    shown?: boolean
}

const props = withDefaults(defineProps<TooltipProps>(), {
    shown: undefined,
})
const triggers = computed((): TriggerEvent[] => {
    if (props.shown !== undefined || !props.content) {
        return []
    }
    return ['hover', 'focus']
})
</script>
<style>
.v-popper__popper.v-popper--theme-common-tooltip .v-popper__wrapper {
    color: #000;
    background-color: #c0ff47;
    white-space: nowrap;
    min-width: 30px;
    text-align: center;
    border-radius: 1000px;
    opacity: 1;
}

.v-popper__popper.v-popper--theme-common-tooltip .v-popper__inner {
    color: inherit;
    background-color: inherit;
    border-radius: inherit;
    padding: 8px 12px;
}

.v-popper__popper[data-popper-placement^='top'].v-popper--theme-common-tooltip .v-popper__arrow-outer {
    top: -1px;
}

.v-popper__popper[data-popper-placement^='bottom'].v-popper--theme-common-tooltip .v-popper__arrow-container {
    top: 1px;
}

.v-popper__popper[data-popper-placement^='left'].v-popper--theme-common-tooltip .v-popper__arrow-container {
    right: -9px;
}

.v-popper__popper[data-popper-placement^='right'].v-popper--theme-common-tooltip .v-popper__arrow-container {
    left: 1px;
}

.v-popper__popper.v-popper--theme-common-tooltip .v-popper__wrapper .v-popper__arrow-container .v-popper__arrow-outer {
    border-color: #c0ff47;
}

.v-popper__popper[data-popper-placement^='bottom'].v-popper--theme-common-tooltip.v-popper__popper--show-from
    .v-popper__wrapper,
.v-popper__popper[data-popper-placement^='bottom'].v-popper--theme-common-tooltip.v-popper__popper--hide-to
    .v-popper__wrapper {
    transform: translate(0, 8px);
    transition: transform 0.1s ease-out;
}

.v-popper__popper[data-popper-placement^='bottom'].v-popper--theme-common-tooltip.v-popper__popper--show-to
    .v-popper__wrapper,
.v-popper__popper[data-popper-placement^='bottom'].v-popper--theme-common-tooltip.v-popper__popper--hide-from
    .v-popper__wrapper {
    transform: translate(0, 13px);
    transition: transform 0.2s ease-out;
}

.v-popper__popper[data-popper-placement^='top'].v-popper--theme-common-tooltip.v-popper__popper--show-from
    .v-popper__wrapper,
.v-popper__popper[data-popper-placement^='top'].v-popper--theme-common-tooltip.v-popper__popper--hide-to
    .v-popper__wrapper {
    transform: translate(0, 5px);
    transition: transform 0.1s ease-out;
}

.v-popper__popper[data-popper-placement^='top'].v-popper--theme-common-tooltip.v-popper__popper--show-to
    .v-popper__wrapper,
.v-popper__popper[data-popper-placement^='top'].v-popper--theme-common-tooltip.v-popper__popper--hide-from
    .v-popper__wrapper {
    transform: translate(0, 0);
    transition: transform 0.2s ease-out;
}

.v-popper__popper[data-popper-placement^='left'].v-popper--theme-common-tooltip.v-popper__popper--show-to
    .v-popper__wrapper,
.v-popper__popper[data-popper-placement^='left'].v-popper--theme-common-tooltip.v-popper__popper--hide-from
    .v-popper__wrapper {
    transform: translate(-8px);
    transition: transform 0.2s ease-out;
}

.v-popper__popper[data-popper-placement^='left'].v-popper--theme-common-tooltip.v-popper__popper--show-from
    .v-popper__wrapper,
.v-popper__popper[data-popper-placement^='left'].v-popper--theme-common-tooltip.v-popper__popper--hide-to
    .v-popper__wrapper {
    transform: translate(-3px);
    transition: transform 0.1s ease-out;
}

.v-popper__popper[data-popper-placement^='right'].v-popper--theme-common-tooltip.v-popper__popper--show-to
    .v-popper__wrapper,
.v-popper__popper[data-popper-placement^='right'].v-popper--theme-common-tooltip.v-popper__popper--hide-from
    .v-popper__wrapper {
    transform: translate(3px);
    transition: transform 0.2s ease-out;
}

.v-popper__popper[data-popper-placement^='right'].v-popper--theme-common-tooltip.v-popper__popper--show-from
    .v-popper__wrapper,
.v-popper__popper[data-popper-placement^='right'].v-popper--theme-common-tooltip.v-popper__popper--hide-to
    .v-popper__wrapper {
    transform: translate(8px);
    transition: transform 0.1s ease-out;
}
</style>
