<template>
    <div>
        <div :class="[$style.promo, $style[props.color]]">
            <div :class="$style.fennecs">
                <Icon name="fennek_coin_small" />
                <Typography
                    size="l"
                    :responsive="false"
                    accent
                    :class="$style.promo_label"
                >
                    {{ props.label }}
                </Typography>
            </div>
            <slot :handleClick="onClick" />
        </div>
    </div>
</template>
<script setup lang="ts">
import Icon from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'
import { PromoActions } from '@/components_new/PromoItems/types'
import { usePromoStore } from '@/components_new/PromoItems/store'

interface PromoHintProps {
    label: string
    color?: 'purple' | 'golden'
}

const props = withDefaults(defineProps<PromoHintProps>(), {
    color: 'purple',
})

const promoStore = usePromoStore()

const emit = defineEmits<{ activatePromo: [] }>()

function onClick(action: PromoActions) {
    promoStore.setPromoAction(action)
    emit('activatePromo')
}
</script>
<style module>
.promo {
    display: flex;
    padding: 8px 8px 8px 16px;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    background:
        linear-gradient(#000000, #000000) padding-box,
        var(--border-gradient) border-box;
    border-radius: 1000px;
    border: 1px solid transparent;
}
.purple {
    --border-gradient: linear-gradient(160deg, #9747ff80, #000000);
}

.golden {
    --border-gradient: linear-gradient(160deg, #ffc850, #000000);
}

.promo_label {
    text-align: center;
}
.fennecs {
    display: flex;
    gap: 6px;
    align-items: center;
}
</style>
