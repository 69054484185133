<template>
    <!-- eslint-disable vuejs-accessibility/label-has-for -->
    <!-- we have for, but eslint does not understand it, because we use <component :is="..."> -->
    <label
        :class="[
            $attrs.class,
            $style.rootLabel,
            $style[size],
            {
                [$style.disabled]: disabled,
                [$style.invalid]: isInvalid,
                [$style.filled]: !!inputValue,
                [$style.withVisibleLabel]: !withHiddenLabel,
            },
        ]"
        :for="id"
    >
        <template v-if="labelText">
            <span
                v-if="!withHiddenLabel"
                :class="$style.labelText"
                aria-hidden="true"
            >
                {{ labelText }}
            </span>
            <span :class="[$style.labelText, $style.animatedLabelText, { [$style.invisible]: withHiddenLabel }]">
                {{ labelText }}
            </span>
        </template>

        <component
            v-bind="{ ...$attrs, class: undefined }"
            :is="is"
            :id="id"
            :class="$style.input"
            :disabled="disabled"
            :name="name"
            :type="props.type"
            :value="inputValue"
            :placeholder="placeholder"
            @input="onInput($event as UIChangeEvent<HTMLInputElement>)"
        />
    </label>
</template>

<script lang="ts" setup generic="T extends InputTypeHTMLAttribute | undefined">
import { computed, InputTypeHTMLAttribute, ref } from 'vue'

type ValueType = T extends 'number' ? number : string

const props = defineProps<{
    is?: 'input' | 'textarea'
    id: string
    name: string
    labelText?: string
    modelValue?: ValueType
    disabled?: boolean
    withHiddenLabel?: boolean
    placeholder?: string
    value?: ValueType
    size?: 'l' | 'm' | 's' | 'xs'
    type?: T
}>()

const size = computed(() => props.size || 'm')
const is = computed(() => props.is || 'input')
const withHiddenLabel = computed(() => !props.labelText || props.withHiddenLabel)
const isInvalid = ref(false)

const emit = defineEmits<{ input: [e: UIChangeEvent<HTMLInputElement>]; 'update:modelValue': [value: ValueType] }>()

const inputValue = computed(() => props.modelValue ?? props.value)

const shouldConvertToNumber = computed(() => props.type === 'number' || typeof inputValue.value === 'number')

const convertToNumber = (value: string) => (Number.isFinite(+value) ? +value : 0)

const onInput = (e: UIChangeEvent<HTMLInputElement>) => {
    isInvalid.value = !e.target.validity.valid
    const newValue = shouldConvertToNumber.value ? convertToNumber(e.target.value) : e.target.value
    emit('update:modelValue', newValue as ValueType)
    emit('input', e)
}
</script>

<style module>
/* ROOT STYLES AND DEFINITIONS */

.rootLabel * {
    box-sizing: border-box;
    font-family: var(--new-font-family);
    font-weight: var(--new-font-weight-default);
}

.rootLabel {
    --input-scrollbar-thumb-color: var(--new-carbon-500);
    --input-text-color: var(--new-white);
    --label-text-color: var(--new-white-60);
    --input-border-color: transparent;
    --input-background-color: var(--new-carbon-400);
    --input-side-padding: var(--gap-medium);

    --border-animation-duration: 0.1s;
    --background-animation-duration: 0.15s;
    --label-animation-duration: 0.15s;

    box-sizing: border-box;
    position: relative;
    cursor: text;

    display: flex;
    flex-direction: column;
    gap: var(--input-to-label-gap);

    /* vertical padding is on input itself, when there is no label */
    padding: 0 var(--input-side-padding);
    border-radius: var(--input-border-radius);
    /* animated border background */
    background: linear-gradient(to right, var(--input-border-color), var(--input-border-color)) center center / 100%
        100% no-repeat;
}

/* before is used for main background */
.rootLabel::before {
    pointer-events: none;
    border-radius: var(--input-border-radius);
    content: '';
    position: absolute;
    /* 1px gives us a way to see the animated border */
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    transition: background-color var(--background-animation-duration) ease-in-out;
    background: var(--input-background-color);
}

.rootLabel.withVisibleLabel {
    padding: var(--input-vertical-padding) var(--input-side-padding);
}

/* SIZES */

.rootLabel.m {
    --input-to-label-gap: var(--gap-xsmall);

    /* exactly pixel perfect with design */
    --input-vertical-padding: 11px;
    --input-vertical-padding-large: 20px;

    /* line-height === font-size by design (important for animations + pixel perfect in requirements) */
    --input-font-size: var(--new-font-size-paragraph-m);
    --input-line-height: var(--new-font-size-paragraph-m);
    --input-letter-spacing: var(--new-letter-spacing-paragraph-m);

    --input-border-radius: var(--radius-medium);

    --label-font-size: var(--new-font-size-label-s);
    --label-line-height: var(--new-line-height-label);
}

/* size L is just an example of extending component to other sizes. Please, check before use */
.rootLabel.l {
    --input-to-label-gap: var(--gap-xsmall);

    --input-vertical-padding: 14px;
    /* exactly pixel perfect with design */
    --input-vertical-padding-large: 23px;

    /* line-height === font-size by design (important for animations + pixel perfect in requirements) */
    --input-font-size: var(--new-font-size-paragraph-l);
    --input-line-height: var(--new-font-size-paragraph-l);
    --input-letter-spacing: var(--new-letter-spacing-paragraph-l);

    --input-border-radius: var(--radius-regular);

    --label-font-size: var(--new-font-size-label-s);
    --label-line-height: var(--new-line-height-label);
}

/* BASIC ELEMENTS */

.labelText {
    opacity: 0;
    visibility: hidden;
    font-size: var(--label-font-size);
    line-height: var(--label-line-height);
}

.rootLabel textarea {
    min-height: 72px;
}

.animatedLabelText {
    visibility: visible;
    opacity: 1;
    color: var(--label-text-color);
    transition:
        transform var(--label-animation-duration),
        font-size var(--label-animation-duration);
    position: absolute;
    top: 0;
    font-size: var(--input-font-size);
    line-height: var(--input-line-height);
    letter-spacing: var(--input-letter-spacing);

    transform: translate3d(0, var(--input-vertical-padding-large), 0);
}

.animatedLabelText.invisible {
    opacity: 0;
}

.input {
    color: var(--input-text-color);
    caret-color: var(--new-accent-purple);
    border: none;
    z-index: 1;
    padding: var(--input-vertical-padding-large) 0;
    font-size: var(--input-font-size);
    letter-spacing: var(--input-letter-spacing);
    height: var(--input-line-height);
    box-sizing: content-box;
    scrollbar-color: var(--input-scrollbar-thumb-color) transparent;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active,
.input:-webkit-autofill::first-line {
    /* remove ugly autofill background color and styles */
    border: none;
    -webkit-background-clip: text;
    -webkit-box-shadow: inherit;
    -webkit-text-fill-color: inherit;
    transition: background-color 9999s ease-in-out 0s;
    color: var(--input-text-color);
}

.input::placeholder {
    color: var(--label-text-color);
}

.input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.rootLabel.withVisibleLabel .input::placeholder {
    opacity: 0;
}

.rootLabel.withVisibleLabel .input {
    /* padding is on the root label when labels are visible */
    padding: 0;
}

/* STATES */

.rootLabel.disabled {
    cursor: not-allowed;
    --input-border-color: var(--new-carbon-400);
    --input-background-color: var(--new-carbon-100);

    --input-text-color: var(--new-white-40);
    --label-text-color: var(--new-white-40);
}

.rootLabel.disabled .input {
    cursor: not-allowed;
}

.rootLabel:hover:not(.disabled) {
    --input-border-color: var(--new-carbon-500);
    animation: animateBorder var(--border-animation-duration) ease-out forwards;
}

.rootLabel:focus-within:not(.disabled) {
    --input-border-color: var(--new-carbon-500);
    --input-background-color: var(--new-carbon-100);
    animation: animateBorder var(--border-animation-duration) ease-out forwards;
}

.rootLabel:focus-within:not(.disabled) .animatedLabelText,
.rootLabel.filled .animatedLabelText {
    font-size: var(--label-font-size);
    line-height: var(--label-line-height);

    transform: translate3d(0, var(--input-vertical-padding), 0);
}

.rootLabel.invalid:not(:focus-within) {
    animation: animateBorder var(--border-animation-duration) ease-out forwards;
    --input-border-color: var(--new-rose-400);
    --input-background-color: var(--new-carbon-100);
}

/* ANIMATIONS */

@keyframes animateBorder {
    0% {
        background-size: 0 100%;
    }

    25% {
        background-size: 10% 100%;
    }

    25% {
        background-size: 90% 100%;
    }

    100% {
        background-size: 100% 100%;
    }
}
</style>
