<template>
    <div
        :class="$style.details"
        itemscope
        itemtype="http://schema.org/WebApplication"
    >
        <meta
            itemprop="applicationCategory"
            content="Game"
        />
        <Typography
            is="h1"
            size="xs"
            :responsive="false"
            itemprop="name"
        >
            {{ props.game.title }}
        </Typography>
        <meta
            itemprop="image"
            :content="props.game.preview"
        />
        <meta
            itemprop="url"
            :content="`game/${props.game.hru}`"
        />
        <div
            itemprop="offers"
            itemscope
            itemtype="http://schema.org/Offer"
            :class="$style.markup_el"
        >
            <meta
                itemprop="price"
                content="0"
            />
            <meta
                itemprop="priceCurrency"
                content="USD"
            />
            <meta
                itemprop="availability"
                content="https://schema.org/InStock"
            />
        </div>
        <div
            v-for="(screenshot, index) in props.game.screenshots"
            :key="index"
            itemprop="screenshot"
            itemscope
            itemtype="http://schema.org/ImageObject"
            :class="$style.markup_el"
        >
            <meta
                itemprop="thumbnailUrl"
                :content="screenshot"
            />
        </div>
        <Typography
            is="div"
            type="paragraph"
            size="xs"
            :class="$style.meta"
        >
            <div itemprop="operatingSystem">
                <span :class="$style.meta__item_key"> {{ t('platforms') }}: </span>
                <span :class="$style.meta__item_value">
                    {{ gamePlatforms }}
                </span>
            </div>
            <div>
                <span :class="$style.meta__item_key"> {{ t('categories') }}: </span>
                <span :class="$style.meta__item_value">
                    <Link
                        v-for="(category, index) in gameCategories"
                        :key="category"
                        :to="`/category/${category}`"
                        color="white-80"
                        itemprop="applicationSubCategory"
                    >
                        {{ t(category) }}{{ index !== gameCategories.length - 1 ? ', ' : '' }}
                    </Link>
                </span>
            </div>
            <div>
                <span :class="$style.meta__item_key"> {{ t('tags') }}: </span>
                <span :class="$style.meta__item_value">
                    {{ gameTags }}
                </span>
            </div>
        </Typography>
        <div :class="$style.hr" />
        <SeoText
            :content="gameDescription"
            color="white-80"
            itemprop="description"
        />
    </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { GameType } from '@/types'
import { categoriesTranslation } from '@/utils/translations/categories'
import Typography from '@/components_new/Typography.vue'
import Link from '@/components_new/Link.vue'
import { gameTranslation, getPlatforms } from '@/pages/Games/utils'
import SeoText from '@/components_new/SeoText.vue'

interface GameCardProps {
    game: GameType
}

const props = defineProps<GameCardProps>()

const { t } = useI18n({
    messages: {
        en: {
            ...categoriesTranslation.en,
            ...gameTranslation.en,
            platforms: 'Platforms',
            categories: 'Categories',
            tags: 'Tags',
            showFullDesc: 'Show all description',
        },
    },
})

const gameDescription = computed(() => {
    let text
    if (props.game.seo_text) {
        text = `${props.game.seo_text}`
    } else {
        text = `${props.game.description}`
        if (props.game.how_to_play_text) {
            text += `\n\n\n\n${props.game.how_to_play_text}`
        }
    }
    text = text.replace(/\n•(.+)/gm, '<li>$1</li>')
    text = text.replace(/(<li>.+<\/li>)+/g, '<ul>$1</ul>')
    text = text.replace(/<p><\/p>/g, '')
    text = text.replace(/\n\n/g, '<br>')
    text = text.replace(/<(\/?)h1>/g, '<$1h2>')
    return text
})

const gamePlatforms = computed(() => getPlatforms(props.game).map(t).join(', '))

const gameTags = computed(() => {
    const text = props.game.tags.slice(0, 5).join(', ')
    return text
})
const gameCategories = computed(() => props.game.categories.slice(0, 5))
</script>
<style module>
.details {
    border-radius: 16px;
    background: #17171a;
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.meta {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px 16px;
}

.meta__item_key {
    color: rgba(255, 255, 255, 0.4);
}

.meta__item_value {
    color: rgba(255, 255, 255, 0.8);
}

.hr {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.markup_el {
    margin-top: -12px;
}
</style>
