import { GameType } from '@/types'
import { Ref } from 'vue'

export function getMetaURL(hru?: string) {
    return `${process.env.VUE_APP_HOST_URL}/game/${hru}`
}
export function getPlatforms(game: GameType | undefined): string[] {
    const { is_ios: ios, is_android: android, is_desktop: desktop } = game || {}
    const platformsValues = { ios, android, desktop }
    return Object.entries(platformsValues)
        .filter(([, value]) => value)
        .map(([key]) => key)
}

export type GenerateGameHeadParams = {
    game: Ref<GameType | undefined>
}

export const gameTranslation: Record<string, Record<string, string>> = {
    en: {
        desktop: 'Desktop',
        android: 'Android',
        ios: 'iOS',
    },
}
