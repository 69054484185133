<!-- eslint-disable max-len -->
<template>
    <div class="framer-h4cven">
        <div
            class="framer-tugsir"
            data-framer-name="One"
        >
            <div
                class="framer-kfasuu"
                data-framer-name="image"
            >
                <div
                    data-framer-background-image-wrapper="true"
                    style="position: absolute; border-radius: inherit; inset: 0px"
                >
                    <img
                        src="/landing/jCt5it4siOadLb1ltIZ5mabmjMQ.webp"
                        alt=""
                        loading="lazy"
                        style="
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-fit: cover;
                            image-rendering: auto;
                        "
                    />
                </div>
            </div>
            <div class="framer-w8l54i">
                <div
                    class="framer-1ozsu7r"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <h3
                        class="framer-text framer-styles-preset-12lj5ox"
                        data-styles-preset="YckFIlg3V"
                        style="text-align: center"
                    >
                        Battle Arena
                    </h3>
                </div>
                <div
                    class="framer-wilg2l"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        ⭐ 4,5 rating
                    </p>
                </div>
                <div
                    class="framer-mmjntd"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        2 499 000 players
                    </p>
                </div>
            </div>
        </div>
        <div
            class="framer-lza0ev"
            data-framer-name="One"
        >
            <div
                class="framer-1irk3xr"
                data-framer-name="image"
            >
                <div
                    data-framer-background-image-wrapper="true"
                    style="position: absolute; border-radius: inherit; inset: 0px"
                >
                    <img
                        src="/landing/rma8vlows23hU12ZEhpEOAJVZSk.webp"
                        alt=""
                        loading="lazy"
                        style="
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-fit: cover;
                            image-rendering: auto;
                        "
                    />
                </div>
            </div>
            <div class="framer-7yb4dz">
                <div
                    class="framer-vaz4gr"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <h3
                        class="framer-text framer-styles-preset-12lj5ox"
                        data-styles-preset="YckFIlg3V"
                        style="text-align: center"
                    >
                        Mergest Kingdom
                    </h3>
                </div>
                <div
                    class="framer-arzg8i"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        ⭐ 4,5 rating
                    </p>
                </div>
                <div
                    class="framer-lorp91"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        1 399 000 players
                    </p>
                </div>
            </div>
        </div>
        <div
            class="framer-1gfack6"
            data-framer-name="One"
        >
            <div
                class="framer-1977ig8"
                data-framer-name="image"
            >
                <div
                    data-framer-background-image-wrapper="true"
                    style="position: absolute; border-radius: inherit; inset: 0px"
                >
                    <img
                        src="/landing/nR9WFiJHVrXb30LHxmImrAbhP8.png"
                        alt=""
                        loading="lazy"
                        style="
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-fit: cover;
                            image-rendering: auto;
                        "
                    />
                </div>
            </div>
            <div class="framer-1h0rhgp">
                <div
                    class="framer-btcb8n"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <h3
                        class="framer-text framer-styles-preset-12lj5ox"
                        data-styles-preset="YckFIlg3V"
                        style="text-align: center"
                    >
                        Tropical<br class="framer-text" />Merge
                    </h3>
                </div>
                <div
                    class="framer-coc72x"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        ⭐ 4,5 rating
                    </p>
                </div>
                <div
                    class="framer-1umdead"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        2 199 000 players
                    </p>
                </div>
            </div>
        </div>
        <div
            class="framer-1jurh1l"
            data-framer-name="One"
        >
            <div
                class="framer-e043b1"
                data-framer-name="image"
            >
                <div
                    data-framer-background-image-wrapper="true"
                    style="position: absolute; border-radius: inherit; inset: 0px"
                >
                    <img
                        src="/landing/u4xpY1OhwIA7U2A3AQJVEtdNuVg.png"
                        alt=""
                        loading="lazy"
                        style="
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-fit: cover;
                            image-rendering: auto;
                        "
                    />
                </div>
            </div>
            <div class="framer-1s4uxro">
                <div
                    class="framer-11k99kg"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <h3
                        class="framer-text framer-styles-preset-12lj5ox"
                        data-styles-preset="YckFIlg3V"
                        style="text-align: center"
                    >
                        Wood Block Classic
                    </h3>
                </div>
                <div
                    class="framer-gveuzn"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        ⭐ 4,5 rating
                    </p>
                </div>
                <div
                    class="framer-k7sjzw"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        3 299 000 players
                    </p>
                </div>
            </div>
        </div>
        <div
            class="framer-nq9n0s"
            data-framer-name="One"
        >
            <div
                class="framer-1ngx2k8"
                data-framer-name="image"
            >
                <div
                    data-framer-background-image-wrapper="true"
                    style="position: absolute; border-radius: inherit; inset: 0px"
                >
                    <img
                        src="/landing/66FjVZut25mLO4QQUgXXXThXG60.webp"
                        alt=""
                        loading="lazy"
                        style="
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-fit: cover;
                            image-rendering: auto;
                        "
                    />
                </div>
            </div>
            <div class="framer-1wlrypw">
                <div
                    class="framer-2auyof"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <h3
                        class="framer-text framer-styles-preset-12lj5ox"
                        data-styles-preset="YckFIlg3V"
                        style="text-align: center"
                    >
                        Global<br class="framer-text" />City
                    </h3>
                </div>
                <div
                    class="framer-66u9qk"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        ⭐ 4,5 rating
                    </p>
                </div>
                <div
                    class="framer-ohtc4h"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        2 999 000 players
                    </p>
                </div>
            </div>
        </div>
        <div
            class="framer-1k5assy"
            data-framer-name="One"
        >
            <div
                class="framer-1nokh3v"
                data-framer-name="image"
            >
                <div
                    data-framer-background-image-wrapper="true"
                    style="position: absolute; border-radius: inherit; inset: 0px"
                >
                    <img
                        src="/landing/p4ojyfb1J3WZVxthN9Fzhf88mk.png"
                        alt=""
                        loading="lazy"
                        style="
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-fit: cover;
                            image-rendering: auto;
                        "
                    />
                </div>
            </div>
            <div class="framer-1xyb5bo">
                <div
                    class="framer-15h399j"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <h3
                        class="framer-text framer-styles-preset-12lj5ox"
                        data-styles-preset="YckFIlg3V"
                        style="text-align: center"
                    >
                        Wild Worm Hunt
                    </h3>
                </div>
                <div
                    class="framer-1m9kt8j"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        ⭐ 4,5 rating
                    </p>
                </div>
                <div
                    class="framer-1s27t7d"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        499 000 players
                    </p>
                </div>
            </div>
        </div>
        <div
            class="framer-13a6013"
            data-framer-name="One"
        >
            <div
                class="framer-kxi2i7"
                data-framer-name="image"
            >
                <div
                    data-framer-background-image-wrapper="true"
                    style="position: absolute; border-radius: inherit; inset: 0px"
                >
                    <img
                        src="/landing/jCt5it4siOadLb1ltIZ5mabmjMQ.webp"
                        alt=""
                        loading="lazy"
                        style="
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-fit: cover;
                            image-rendering: auto;
                        "
                    />
                </div>
            </div>
            <div class="framer-1k15y2r">
                <div
                    class="framer-sx5gwd"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <h3
                        class="framer-text framer-styles-preset-12lj5ox"
                        data-styles-preset="YckFIlg3V"
                        style="text-align: center"
                    >
                        Battle Arena
                    </h3>
                </div>
                <div
                    class="framer-1yfiqfz"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        ⭐ 4,5 rating
                    </p>
                </div>
                <div
                    class="framer-mcm9l"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        2 499 000 players
                    </p>
                </div>
            </div>
        </div>
        <div
            class="framer-4h7ixo"
            data-framer-name="One"
        >
            <div
                class="framer-1kf1yxv"
                data-framer-name="image"
            >
                <div
                    data-framer-background-image-wrapper="true"
                    style="position: absolute; border-radius: inherit; inset: 0px"
                >
                    <img
                        src="/landing/nR9WFiJHVrXb30LHxmImrAbhP8.png"
                        alt=""
                        loading="lazy"
                        style="
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-fit: cover;
                            image-rendering: auto;
                        "
                    />
                </div>
            </div>
            <div class="framer-1nawu7w">
                <div
                    class="framer-1gv9afb"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <h3
                        class="framer-text framer-styles-preset-12lj5ox"
                        data-styles-preset="YckFIlg3V"
                        style="text-align: center"
                    >
                        Tropical<br class="framer-text" />Merge
                    </h3>
                </div>
                <div
                    class="framer-1hcbadq"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        ⭐ 4,5 rating
                    </p>
                </div>
                <div
                    class="framer-14ps5yt"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        2 199 000 players
                    </p>
                </div>
            </div>
        </div>
        <div
            class="framer-1jssx8v"
            data-framer-name="One"
        >
            <div class="framer-15o41wf">
                <div
                    data-framer-background-image-wrapper="true"
                    style="position: absolute; border-radius: inherit; inset: 0px"
                >
                    <img
                        src="/landing/jCt5it4siOadLb1ltIZ5mabmjMQ.webp"
                        alt=""
                        loading="lazy"
                        style="
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-fit: cover;
                            image-rendering: auto;
                        "
                    />
                </div>
            </div>
            <div class="framer-1yxyo0a">
                <div
                    class="framer-vwwym9"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <h3
                        class="framer-text framer-styles-preset-12lj5ox"
                        data-styles-preset="YckFIlg3V"
                        style="text-align: center"
                    >
                        Battle Arena
                    </h3>
                </div>
                <div
                    class="framer-1auck2d"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        ⭐ 4,5 rating
                    </p>
                </div>
                <div
                    class="framer-123wgu3"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        2 499 000 players
                    </p>
                </div>
            </div>
        </div>
        <div
            class="framer-y9xv7v"
            data-framer-name="One"
        >
            <div
                class="framer-1om7ft9"
                data-framer-name="image"
            >
                <div
                    data-framer-background-image-wrapper="true"
                    style="position: absolute; border-radius: inherit; inset: 0px"
                >
                    <img
                        src="/landing/u4xpY1OhwIA7U2A3AQJVEtdNuVg.png"
                        alt=""
                        loading="lazy"
                        style="
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-fit: cover;
                            image-rendering: auto;
                        "
                    />
                </div>
            </div>
            <div class="framer-19k7ygq">
                <div
                    class="framer-1l4xuen"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <h3
                        class="framer-text framer-styles-preset-12lj5ox"
                        data-styles-preset="YckFIlg3V"
                        style="text-align: center"
                    >
                        Wood Block Classic
                    </h3>
                </div>
                <div
                    class="framer-vgukbq"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        ⭐ 4,5 rating
                    </p>
                </div>
                <div
                    class="framer-2v7vcy"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        3 299 000 players
                    </p>
                </div>
            </div>
        </div>
        <div
            class="framer-1721bms"
            data-framer-name="One"
        >
            <div
                class="framer-1g8cq5a"
                data-framer-name="image"
            >
                <div
                    data-framer-background-image-wrapper="true"
                    style="position: absolute; border-radius: inherit; inset: 0px"
                >
                    <img
                        src="/landing/66FjVZut25mLO4QQUgXXXThXG60.webp"
                        alt=""
                        loading="lazy"
                        style="
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-fit: cover;
                            image-rendering: auto;
                        "
                    />
                </div>
            </div>
            <div class="framer-1xf30vd">
                <div
                    class="framer-1gifwvr"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <h3
                        class="framer-text framer-styles-preset-12lj5ox"
                        data-styles-preset="YckFIlg3V"
                        style="text-align: center"
                    >
                        Global<br class="framer-text" />City
                    </h3>
                </div>
                <div
                    class="framer-eq6q1o"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        ⭐ 4,5 rating
                    </p>
                </div>
                <div
                    class="framer-9xg7hp"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        2 999 000 players
                    </p>
                </div>
            </div>
        </div>
        <div
            class="framer-12ykmw6"
            data-framer-name="One"
        >
            <div
                class="framer-8x8uav"
                data-framer-name="image"
            >
                <div
                    data-framer-background-image-wrapper="true"
                    style="position: absolute; border-radius: inherit; inset: 0px"
                >
                    <img
                        src="/landing/p4ojyfb1J3WZVxthN9Fzhf88mk.png"
                        alt=""
                        loading="lazy"
                        style="
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-fit: cover;
                            image-rendering: auto;
                        "
                    />
                </div>
            </div>
            <div class="framer-yvzuyx">
                <div
                    class="framer-na3kvb"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <h3
                        class="framer-text framer-styles-preset-12lj5ox"
                        data-styles-preset="YckFIlg3V"
                        style="text-align: center"
                    >
                        Wild Worm Hunt
                    </h3>
                </div>
                <div
                    class="framer-1o6lh4s"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        ⭐ 4,5 rating
                    </p>
                </div>
                <div
                    class="framer-16y4jyv"
                    style="
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    "
                >
                    <p
                        class="framer-text framer-styles-preset-dhi450"
                        data-styles-preset="rGkGsoUve"
                        style="text-align: center"
                    >
                        499 000 players
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import './styles.css'
</script>
