const HOST = process.env.API_HOST
export function request<T>(url: string, config: RequestInit = {}) {
    return fetch(`${HOST}${url}`, {
        headers: {
            'content-type': 'application/json',
        },
        ...config,
    }).then(async (resp) => {
        try {
            if (
                resp.headers.get('content-type') === 'application/json' ||
                resp.headers.get('content-type') === 'application/json; charset=utf-8'
            ) {
                const body = await resp.json()
                if (resp.ok) {
                    return {
                        originalResponse: resp,
                        data: body,
                        errors: [],
                    }
                }
                return {
                    originalResponse: resp,
                    data: null,
                    errors: body.errors || [body.message],
                }
            }
            return {
                originalResponse: resp,
                data: (await resp.text()) as unknown as T, // should be a string
                errors: [],
            }
        } catch (error) {
            const message = (error as Error).toString()
            return {
                originalResponse: resp,
                data: null,
                errors: [message],
            }
        }
    })
}
