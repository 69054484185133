<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
    <div
        :class="[styles.overlay, { [styles.visible]: open }]"
        @click="onClose"
    >
        <form
            :class="[styles.popup, styles[type]]"
            @submit="collectDataAndSubmit"
            @click="stopEvent"
        >
            <label
                v-if="FIELDS_BY_TYPE[type].includes('name')"
                for="form-name"
                :class="styles.labelRow"
            >
                Name and surname
                <input
                    id="form-name"
                    name="name"
                    :class="styles.input"
                    @change="inputChangeHandler($event as UIChangeEvent<HTMLInputElement>)"
                />
            </label>
            <label
                v-if="FIELDS_BY_TYPE[type].includes('email')"
                for="form-email"
                :class="styles.labelRow"
            >
                Email
                <input
                    id="form-email"
                    name="email"
                    :class="styles.input"
                    @change="inputChangeHandler"
                />
            </label>
            <label
                v-if="FIELDS_BY_TYPE[type].includes('company')"
                for="form-company"
                :class="styles.labelRow"
            >
                Company
                <input
                    id="form-company"
                    name="company"
                    :class="styles.input"
                    @change="inputChangeHandler"
                />
            </label>
            <label
                v-if="FIELDS_BY_TYPE[type].includes('link')"
                for="form-link"
                :class="styles.labelRow"
            >
                {{ TEXTS[type].link }}
                <input
                    id="form-link"
                    name="link"
                    :class="styles.input"
                    @change="inputChangeHandler"
                />
            </label>
            <label
                v-if="FIELDS_BY_TYPE[type].includes('mau')"
                for="form-mau"
                :class="styles.labelRow"
            >
                MAU Audience
                <input
                    id="form-mau"
                    name="mau"
                    :class="styles.input"
                    @change="inputChangeHandler"
                />
            </label>
            <button
                type="submit"
                :class="[styles.button, styles[type]]"
            >
                {{ TEXTS[type].submit }}
            </button>
        </form>
    </div>
</template>
<script lang="ts" setup>
import { ref, watch } from 'vue'
import styles from './landing-form.module.css'

type TypeOfLandingPage = 'adv' | 'pub' | 'dev'

export type FormSubmitData = {
    type: TypeOfLandingPage
    email: string
    name: string
    company: string
    link?: string
    mau?: string
}

type LandingFormProps = {
    open: boolean
    type: TypeOfLandingPage
    onClose: () => void
}

const props = defineProps<LandingFormProps>()

const FIELDS_BY_TYPE = {
    adv: ['name', 'email', 'company'],
    pub: ['name', 'email', 'company', 'link', 'mau'],
}

const defaultFormData: Omit<FormSubmitData, 'type'> = {
    email: '',
    name: '',
    company: '',
    link: '',
    mau: '',
}

const TEXTS = {
    adv: {
        submit: 'Join Playgama',
        link: 'Link',
    },
    pub: {
        submit: 'Join Playgama',
        link: 'Link to your site or app',
    },
}

const formData = ref<FormSubmitData>({
    ...defaultFormData,
    type: props.type,
})

watch(
    () => props.type,
    () => {
        formData.value = { ...defaultFormData, type: props.type }
    },
)

watch(
    () => props.open,
    () => {
        if (props.open) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = ''
        }
        return () => {
            document.body.style.overflow = ''
        }
    },
)

const stopEvent = (e: MouseEvent) => {
    e.stopPropagation()
}

const collectDataAndSubmit = (event: SubmitEvent) => {
    event?.preventDefault()
    fetch('/api/feedback/join', {
        method: 'POST',
        cache: 'no-store',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: formData.value.email,
            name: formData.value.name,
            company: formData.value.company,
            link: formData.value.link,
            mau: formData.value.mau,
            type: props.type,
        }),
    })
    props.onClose()
}

const inputChangeHandler = (event: UIChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target
    // @ts-ignore TODO: can type this better, but we rely on name being === formData field name
    formData.value[name] = value
}
</script>
