import { CategoriesType } from '@/types'

import * as twoPlayer from './2Player'
import * as action from './action'
// TODO: was not used, delete?
// import * as adventure from './adventure'
import * as arcade from './arcade'
import * as balloons from './balloons'
import * as boys from './boys'
import * as cards from './cards'
import * as economic from './economic'
import * as educational from './educational'
import * as girls from './girls'
import * as horrors from './horrors'
import * as io from './io'
import * as kids from './kids'
import * as match3 from './match3'
import * as puzzle from './puzzle'
import * as quiz from './quiz'
import * as racing from './racing'
import * as role from './role'
import * as simulator from './simulator'
import * as sports from './sports'
import * as strategy from './strategy'
import * as tabletop from './tabletop'

type CategoryTexts = {
    shortTitle: string
    shortDescription: string
    description: string
    seoText: string
}

export const getCategoriesTexts = (
    categoryNames: Record<CategoriesType, string>,
): Record<CategoriesType, CategoryTexts> => {
    const defaultCategoryTexts = (name: CategoriesType) => ({
        shortTitle: `${categoryNames[name]} Online for Free 🎮 Play now`,
        shortDescription: `Dive into the best ${categoryNames[name]} on Playgama! Enjoy free, high-quality browser games without downloads or registrations. ✨ Play now on desktop, tablet, and mobile.`,
        description: '',
        seoText: '',
    })

    return {
        popular: defaultCategoryTexts('popular'),
        all_games: defaultCategoryTexts('all_games'),
        recommended: defaultCategoryTexts('recommended'),
        trending_now: defaultCategoryTexts('trending_now'),
        new: defaultCategoryTexts('new'),
        random: defaultCategoryTexts('random'),
        '2_player': twoPlayer,
        '3d': defaultCategoryTexts('3d'),
        action,
        adventure: defaultCategoryTexts('adventure'),
        applications: defaultCategoryTexts('applications'),
        arcade,
        baby: defaultCategoryTexts('baby'),
        balloons,
        bejeweled: defaultCategoryTexts('bejeweled'),
        boys,
        cards,
        casino: defaultCategoryTexts('casino'),
        clicker: defaultCategoryTexts('clicker'),
        cooking: defaultCategoryTexts('cooking'),
        economic,
        educational,
        farming: defaultCategoryTexts('farming'),
        girls,
        horrors,
        hypercasual: defaultCategoryTexts('hypercasual'),
        imitations: defaultCategoryTexts('imitations'),
        in_game_purchase: defaultCategoryTexts('in_game_purchase'),
        io,
        kids,
        match3,
        midcore: defaultCategoryTexts('midcore'),
        multiplayer: defaultCategoryTexts('multiplayer'),
        puzzle,
        quiz,
        racing,
        role,
        simulator,
        shooting: defaultCategoryTexts('shooting'),
        sports,
        stickman: defaultCategoryTexts('stickman'),
        strategy,
        soccer: defaultCategoryTexts('soccer'),
        social: defaultCategoryTexts('social'),
        tabletop,
        tests: defaultCategoryTexts('tests'),
        top_picks: defaultCategoryTexts('top_picks'),
    }
}
