<template>
    <PromoBanner
        v-if="isAuthorized && isUserInfoLoaded"
        :description="t('invite_desc')"
        direction="column"
        icon="fennek_coin_small"
        :image="{ src: FriendImg, alt: t('invite_alt') }"
        :title="props.title"
    >
        <template #default="{ handleClick }">
            <Button
                size="l"
                @click="handleClick('invite')"
            >
                {{ t('invite_btn') }}
            </Button>
        </template>
    </PromoBanner>
</template>
<script setup lang="ts">
import Button from '@/components_new/Button.vue'
import PromoBanner from '@/components_new/PromoItems/PromoBanner.vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/store/user-store/user-store'
import { storeToRefs } from 'pinia'
import { promoItemsTranslation } from '@/components_new/PromoItems/utils'
import FriendImg from '../images/friend.png'

interface PromoInviteVerticalProps {
    title?: string
}

const props = withDefaults(defineProps<PromoInviteVerticalProps>(), {
    title: '50 000',
})

const { t } = useI18n({
    messages: {
        en: {
            ...promoItemsTranslation.en,
        },
    },
})

const userStore = useUserStore()
const { isAuthorized, isUserInfoLoaded } = storeToRefs(userStore)
</script>
