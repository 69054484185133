import { defineStore, storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user-store/user-store'
import { computed } from 'vue'

export const useTasksStore = defineStore('tasks', () => {
    const userStore = useUserStore()
    const { tasks, tasksError } = storeToRefs(userStore)

    const notClaimedTasks = computed(() => tasks.value.filter((task) => task.status !== 'CLAIMED'))
    const allTasksClaimed = computed(() => tasks.value.length > 0 && notClaimedTasks.value.length === 0)
    const showTasks = computed(() => !allTasksClaimed.value || tasksError.value)

    async function onTaskClaimed(taskId: string) {
        await userStore.claimUserTasks(taskId)
    }

    async function onReloadClick() {
        await userStore.getUserTasks()
    }
    return {
        onTaskClaimed,
        onReloadClick,
        allTasksClaimed,
        notClaimedTasks,
        tasksError,
        showTasks,
        tasks,
    }
})
