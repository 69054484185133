<template>
    <Typography
        is="ul"
        type="paragraph"
        size="xs"
        :class="$style.breadcrumbs"
        itemscope
        itemtype="https://schema.org/BreadcrumbList"
    >
        <li
            v-for="(item, index) in items"
            :key="item.title"
            itemprop="itemListElement"
            itemscope
            itemtype="https://schema.org/ListItem"
        >
            <span
                v-if="index !== 0"
                :class="$style.sep"
                >/</span
            >
            <Link
                itemprop="item"
                :to="item.link"
                :disabled="item.disabled"
                color="white-60"
                :class="$style.item"
                :style="{ flexShrink: item.shrink }"
            >
                <span itemprop="name">{{ item.title }}</span>
                <meta
                    itemprop="position"
                    :content="index + 1"
                />
            </Link>
        </li>
    </Typography>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { GameType } from '@/types'
import { categoriesTranslation } from '@/utils/translations/categories'
import Typography from '@/components_new/Typography.vue'
import Link from '@/components_new/Link.vue'

const props = defineProps<{
    game?: GameType
}>()

const { t } = useI18n({
    messages: {
        ...categoriesTranslation,
    },
})

const items = computed(() => {
    const category = props.game?.categories[0]
    return [
        {
            title: t('home'),
            link: '/',
            shrink: 1,
        },
        category && {
            title: t(category),
            link: `/category/${category}`,
            shrink: 1,
        },
        props.game && {
            title: props.game.title,
            link: `/game/${props.game.hru}`,
            disabled: true,
            shrink: 0,
        },
    ].filter(Boolean)
})
</script>
<style module>
.breadcrumbs {
    padding: 8px 0;
    border-bottom: 1px solid #17171a;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
}

.breadcrumbs li {
    display: contents;
}

.sep,
.item {
    padding: 4px 2px;
    display: inline-block;
}

.item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
<i18n lang="json">
{
    "en": {
        "home": "Playgama",
        "sep": "/"
    }
}
</i18n>
