<template>
    <PromoBanner
        v-if="isAuthorized && isUserInfoLoaded"
        :description="t('invite_desc')"
        direction="row"
        :title="t('invite_title')"
        :buttonPosition="props.buttonPosition"
        textAlign="left"
        icon="fennek_coin_small"
        :image="{ src: FriendImg, alt: t('invite_alt') }"
    >
        <template #default="{ handleClick }">
            <Button
                size="l"
                @click="handleClick('invite')"
            >
                {{ t('invite_btn') }}
            </Button>
        </template>
    </PromoBanner>
</template>
<script setup lang="ts">
import Button from '@/components_new/Button.vue'
import FriendImg from '@/components_new/PromoItems/images/friend.png'
import PromoBanner from '@/components_new/PromoItems/PromoBanner.vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/store/user-store/user-store'
import { storeToRefs } from 'pinia'
import { promoItemsTranslation } from '@/components_new/PromoItems/utils'

interface PromoInviteHorizontalProps {
    buttonPosition?: 'right' | 'bottom'
}

const props = withDefaults(defineProps<PromoInviteHorizontalProps>(), {
    buttonPosition: 'right',
})

const { t } = useI18n({
    messages: {
        en: {
            ...promoItemsTranslation.en,
            invite_title: '50 000 +',
        },
    },
})

const userStore = useUserStore()
const { isAuthorized, isUserInfoLoaded } = storeToRefs(userStore)
</script>
