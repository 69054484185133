<template>
    <BaseLink
        :to="props.to"
        :href="props.to"
        :target="props.target"
        :class="[$style.link, $style[`color_${props.color}`], props.disabled ? $style.disabled : '']"
        :disabled="props.disabled"
        @click="emit('click', $event)"
    >
        <slot />
    </BaseLink>
</template>

<script lang="ts" setup>
import BaseLink from '@/components_new/BaseLink.vue'
import { RouteLocationRaw } from 'vue-router'

export interface LinkProps {
    disabled?: boolean
    to: string | RouteLocationRaw
    target?: '_self' | '_blank'
    color: 'purple-500' | 'purple-600' | 'golden' | 'white' | 'white-60' | 'white-80'
}

const props = defineProps<LinkProps>()

const emit = defineEmits<{ click: [value: Event] }>()
</script>

<style module>
/* Color themes */

.color_purple-500 {
    --text: #aa76ff;
    --text-hover: #c0ff47;
}

.color_purple-600 {
    --text: #c7abff;
    --text-hover: #c0ff47;
}

.color_golden {
    --text: #ffdca1;
    --text-hover: #ffc850;
}

.color_white {
    --text: #fff;
    --text-hover: #e6d4ff;
}

.color_white-80 {
    --text: rgba(255, 255, 255, 0.8);
    --text-hover: #e6d4ff;
}

.color_white-60 {
    --text: rgba(255, 255, 255, 0.6);
    --text-hover: #e6d4ff;
}

.link {
    color: var(--text);
}

.link:hover,
.link:focus-visible,
.link:has(:focus-visible),
.link:active {
    color: var(--text-hover);
}

.link:focus-visible {
    color: var(--text-hover);
    outline: none;
}

.link.disabled,
.link.disabled:hover,
.link.disabled:active,
.link.disabled:focus-visible,
.link.disabled:has(:focus-visible) {
    color: var(--text);
    cursor: default;
}
</style>
