<template>
    <div :class="$style.end">
        <Button
            v-if="randomGame"
            size="l"
            width="full"
            :target="props.targetUrl"
            :link="`/game/${randomGame.hru}`"
            @click="handleClick"
        >
            {{ t('feeling_lucky') }}
        </Button>
        <Button
            v-else
            size="l"
            width="full"
            disabled
        >
            {{ t('feeling_lucky') }}
        </Button>
        <img
            :src="TheEndImg"
            :alt="t('end_alt')"
            :class="$style.pic"
        />
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import Button from '@/components_new/Button.vue'
import { useCategoriesStore } from '@/store/categories-store'
import TheEndImg from './images/the_end.svg'

const props = defineProps<{
    targetUrl?: '_blank'
}>()
const categoriesStore = useCategoriesStore()

const { randomGame } = storeToRefs(categoriesStore)

const { t } = useI18n()

function handleClick() {
    categoriesStore.updateRandomGame()
}
</script>

<style module>
.end {
    padding: 32px 0;
    width: 272px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 40px;
}

.pic {
    width: 142px;
    height: 64px;
    color: #9747ff;
    align-self: center;
    position: relative;
}
</style>
<i18n lang="json">
{
    "en": {
        "feeling_lucky": "I'm feeling lucky",
        "end_alt": "The End"
    }
}
</i18n>
