import { RewardedBanner } from '@/modules/adv/rewarded-banner'
import { AdProviderStatus, AdvService, OutOfPageAdType } from '../adv'

const SDK_URL = 'https://html5.api.gamedistribution.com/libs/gd/api.js'

type GDSDK = {
    showAd: (type: OutOfPageAdType) => Promise<unknown>
    preloadAd: (type: OutOfPageAdType) => Promise<unknown>
}

export class GameDistributionConnector implements AdvService {
    private bannersMap: Partial<Record<OutOfPageAdType, RewardedBanner>> = {}

    private sdk: GDSDK | undefined

    private currentBanner: OutOfPageAdType | undefined

    private startCurrentAd: (() => void) | undefined

    readonly serviceStatus: Promise<AdProviderStatus>

    constructor() {
        const gameId = window.gd_id || 'cac6ddd807da4fb899f231feb95b865c'
        this.serviceStatus = new Promise((res, rej) => {
            window.GD_OPTIONS = {
                gameId,
                onEvent: (event: { name: string }) => {
                    switch (event.name) {
                        case 'SDK_READY':
                            res(AdProviderStatus.online)
                            this.sdk = window.gdsdk
                            break
                        case 'SDK_REWARDED_WATCH_COMPLETE':
                            this.rewarded()
                            break
                        case 'SDK_GAME_START':
                            this.closeCurrentAd()
                            break
                        case 'AD_ERROR':
                            if (this.currentBanner) {
                                this.bannersMap[this.currentBanner]?.triggerEmpty()
                                this.closeCurrentAd()
                            }
                            break
                        case 'SDK_GAME_PAUSE':
                            if (this.startCurrentAd) {
                                this.startCurrentAd()
                            }
                            break
                        default:
                            break
                    }
                },
            }
            const script = document.createElement('script')
            script.src = SDK_URL
            script.addEventListener('error', () => {
                rej(AdProviderStatus.offline)
            })
            document.body.appendChild(script)
        })
    }

    private rewarded() {
        this.bannersMap.rewarded?.triggerRewarded({})
        setTimeout(() => {
            this.closeCurrentAd()
        }, 1000)
    }

    private closeCurrentAd() {
        setTimeout(() => {
            if (this.currentBanner) {
                this.bannersMap[this.currentBanner]?.triggerClosed()
                this.currentBanner = undefined
                this.startCurrentAd = undefined
            }
        }, 100)
    }

    prepareOutOfPageAd(type: OutOfPageAdType): Promise<RewardedBanner> {
        return this.serviceStatus.then((status) => {
            if (status === AdProviderStatus.offline || !this.sdk) {
                throw new Error('There is something wrong with SDK')
            }
            const banner = new RewardedBanner({})

            return this.sdk.preloadAd(type).then(
                () => {
                    this.bannersMap[type] = banner
                    setTimeout(() => {
                        banner.triggerReady(() => {
                            const closeTimeout = setTimeout(() => {
                                banner.triggerEmpty()
                                this.closeCurrentAd()
                            }, 4000)

                            this.startCurrentAd = () => {
                                clearTimeout(closeTimeout)

                                banner.triggerRendered()

                                setTimeout(() => {
                                    banner.triggerViewable()
                                }, 1000)

                                this.startCurrentAd = undefined
                            }
                            this.currentBanner = type
                            this.sdk?.showAd(type).catch(() => {
                                this.closeCurrentAd()
                            })
                        })
                    }, 20)
                    return banner
                },
                () => {
                    setTimeout(() => {
                        banner.triggerEmpty()
                    }, 20)

                    return banner
                },
            )
        })
    }

    // eslint-disable-next-line class-methods-use-this
    requestPageAd() {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject()
    }

    // eslint-disable-next-line class-methods-use-this
    updateTargeting() {}
}
